import * as Yup from "yup";

export const requiredString = (fieldName) =>
  Yup.string().required(`${fieldName} is required`);

export const emailFieldRequired = () =>
  Yup.string().email("Invalid email address").required("Email is required");

export const emailField = () =>
  Yup.string().nullable().email("Invalid email address").notRequired();

export const passwordFieldRequired = () =>
  Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required");

export const passwordField = () =>
  Yup.string()
    .nullable()
    .min(6, "Password must be at least 6 characters")
    .notRequired();

export const phoneNumberField = () =>
  Yup.string()
    .nullable() // Allow null values
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .notRequired(); // The field is optional

export const phoneNumberFieldRequired = () =>
  Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required");

export const postalCodeFieldRequired = () =>
  Yup.string()
    .matches(/^\d{6}$/, "Postal Code must be 6 digits")
    .nullable()
    .required("Postal Code is required");

export const postalCodeField = () =>
  Yup.string()
    .matches(/^\d{6}$/, "Postal Code must be 6 digits")
    .nullable();

export const requiredFile = (fieldName) =>
  Yup.mixed().required(`${fieldName} is required`);

export const aadharNumberFieldRequired = () =>
  Yup.string()
    .matches(/^[0-9]{12}$/, "Aadhar number must be 12 digits") // Aadhar number must be 12 digits
    .required("Aadhar number is required");
