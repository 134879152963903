import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./MemberPrint.css";
import MemberSanctionLetter from "../components/MemberSanctionLetter";
import JlgGeneratedEmiSchedule from "../components/JlgGenerateEmiSchedule";

const JlgMemberDetailsPrint = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const location = useLocation();
  const { memberDetails } = location.state;
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    const input = ref.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const originalPdfWidth = pdf.internal.pageSize.getWidth();
    const originalPdfHeight = pdf.internal.pageSize.getHeight();
    const pdfWidth = originalPdfWidth * 0.9;
    const pdfHeight = originalPdfHeight * 0.9;
    const xOffset = (originalPdfWidth - pdfWidth) / 2;
    const yOffset = (originalPdfHeight - pdfHeight) / 2;
    const sections = input.querySelectorAll(".section");

    let heightLeft = pdfHeight;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { useCORS: true, scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (
        heightLeft < imgHeight ||
        section.classList.contains("sanction-letter-section")
      ) {
        pdf.addPage();
        heightLeft = pdfHeight;
      }

      pdf.addImage(
        imgData,
        "JPEG",
        xOffset,
        yOffset + (pdfHeight - heightLeft),
        pdfWidth,
        imgHeight
      );
      heightLeft -= imgHeight;

      // Add border to the current page after content is added
      pdf.rect(5, 5, originalPdfWidth - 10, originalPdfHeight - 10); // Rect (x, y, width, height)
    }

    pdf.setProperties({
      title: "Document",
      subject: "Generated Document",
      author: "Your Name",
      keywords: "generated, javascript, web 2.0, ajax",
      creator: "Your App",
    });

    pdf.save(`${memberDetails.memberDetails.memberName}-Loan Application`);
    setLoading(false);
  };
  return (
    <div>
      <div className="button-container">
        <button className="btn btn-danger" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="btn btn-success"
          onClick={handleDownload}
          disabled={loading}
        >
          {loading ? "Downloading..." : "Download PDF"}
        </button>
      </div>
      <div ref={ref} className="print-container">
        <div>
          <div className="header section">
            <p>Application form - Micro Enterprise Loan</p>
          </div>
          <div className="section d-flex justify-content-end ">
            <img
              src={require("../assets/vetrivikaslogo.jpeg")}
              alt="logo"
              className="printlogo"
            />
          </div>
          <MemberSanctionLetter memberDetails={memberDetails} />
        </div>
        <div>
          <div className="section sanction-letter-section d-flex justify-content-end ">
            <img
              src={require("../assets/vetrivikaslogo.jpeg")}
              alt="logo"
              className="printlogo"
            />
          </div>
          <JlgGeneratedEmiSchedule memberDetails={memberDetails} />
          <div
            className="section d-flex justify-content-between"
            style={{ fontSize: "20px" }}
          >
            <div className="mt-5">
              <p> ________________</p>
              <p>Signature Of Applicant</p>
              <p>விண்ணப்பதாரரின் கையொப்பம்</p>
            </div>
            <div className="mt-5">
              <p> ________________</p>
              <p>Signature Of Branch Manager</p>
              <p> கிளை மேலாளர் கையொப்பம் </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JlgMemberDetailsPrint;
