import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginForm from "./screens/LoginForm";
import Dashboard from "./screens/Dashboard";
import LoginCredentials from "./screens/LoginCredentials";
// import { useAdminAuth } from "./components/AdminAuthProvider";
import MemberDetails from "./screens/MemberDetails";
import ApprovedMemberDetails from "./screens/ApprovedMemberDetails";
import RejectedMemberDetails from "./screens/RejectedMemberDetails";
import MemberDetailPrint from "./screens/MemberDetailPrint";
import AdminBranchList from "./components/AdminBranchList";
import LoanFrequencyList from "./screens/LoanFrequencyList";
import LoanTenureList from "./screens/LoanTenureList";
import FundingAgencyList from "./screens/FundingAgencyList";
import DisbursedMemberDetail from "./screens/DisbursedMemberDetail";
import MergedLoanDetails from "./screens/MergedLoanDetails";
import LoanScheduleList from "./screens/LoanScheduleList";
import BusinessList from "./components/BusinessList";
import PrivateRoute from "./PrivateRoute";
import SuperAdminPrivateRoute from "./SuperAdminPrivateRoute";
import FutureDemandReport from "./screens/FutureDemandReport";
import LoanDisbursedReport from "./screens/LoanDisbursedReport";
import MasterDataReport from "./screens/MasterDataReport";
import ClientProspectReport from "./screens/ClientProspectReport";
import OutstandingReport from "./screens/OutstandingReport";
import OverDueReport from "./screens/OverDueReport";
import ParReport from "./screens/ParReport";
import SecurityDepositReport from "./screens/SecurityDepositReport";
import ProcessingChargeReport from "./screens/ProcessingChargeReport";
import AdminDivisionList from "./components/AdminDivisionList";
import AdminRegionList from "./components/AdminRegionList";
import CollectionReport from "./screens/CollectionReport";
import DemandVsCollectionReport from "./screens/DemandVsCollectionReport";
import RejectReport from "./screens/RejectReport";
import AccountStatementReport from "./screens/AccountStatementReport";
import AdminPermissions from "./components/AdminPermissions";
import InsuranceReport from "./screens/InsuranceReport";
import InterestReport from "./screens/InterestReport";
import TransferCro from "./screens/TransferCro";
import EntityDetails from "./components/EntityDetails";
import AddEditEntityDetails from "./components/AddEditEntityDetails";
import CenterDetails from "./screens/CenterDetails";
import JlgMemberDetailsPrint from "./screens/JlgMemberDetailsPrint";
import JlgInsuranceReport from "./screens/JlgInsuranceReport";

export default function App() {
  const entities = [
    {
      path: "fundingAgencies",
      label: "fundingAgencies",
      addEditPath: "addEditFundingAgency",
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />

      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/memberdetails" element={<MemberDetails />} />
        <Route path="/centerDetails" element={<CenterDetails />} />
        <Route path="/approved/members" element={<ApprovedMemberDetails />} />
        <Route path="/rejected/members" element={<RejectedMemberDetails />} />
        <Route path="/memberdetail/print" element={<MemberDetailPrint />} />
        <Route
          path="/memberdetail/JLG/print"
          element={<JlgMemberDetailsPrint />}
        />
        <Route path="/futureDemandReport" element={<FutureDemandReport />} />
        <Route path="/loanDisbursedReport" element={<LoanDisbursedReport />} />
        <Route path="/masterDataReport" element={<MasterDataReport />} />
        <Route
          path="/clientProspectReport"
          element={<ClientProspectReport />}
        />
        <Route path="/outstandingReport" element={<OutstandingReport />} />
        <Route path="/overDueReport" element={<OverDueReport />} />
        <Route path="/parReport" element={<ParReport />} />
        <Route
          path="/securityDepositReport"
          element={<SecurityDepositReport />}
        />
        <Route
          path="/processingChargeReport"
          element={<ProcessingChargeReport />}
        />
        <Route path="/collectionReport" element={<CollectionReport />} />
        <Route
          path="/demandVsCollectionReport"
          element={<DemandVsCollectionReport />}
        />
        <Route path="/rejectReport" element={<RejectReport />} />
        <Route
          path="/accountStatementReport"
          element={<AccountStatementReport />}
        />
        <Route path="/insuranceReport" element={<InsuranceReport />} />
        <Route path="/jlgInsuranceReport" element={<JlgInsuranceReport />} />
        <Route path="/interestReport" element={<InterestReport />} />

        <Route element={<SuperAdminPrivateRoute />}>
          <Route path="/logincredential" element={<LoginCredentials />} />
          <Route path="/branch" element={<AdminBranchList />} />
          <Route path="/division" element={<AdminDivisionList />} />
          <Route path="/region" element={<AdminRegionList />} />
          <Route path="/permissions" element={<AdminPermissions />} />
          <Route path="/transferCro" element={<TransferCro />} />
          <Route path="/loanfrequency/list" element={<LoanFrequencyList />} />
          <Route path="/loantenure/list" element={<LoanTenureList />} />
          <Route path="/funding/agency/list" element={<FundingAgencyList />} />
          <Route path="/disbursed/member" element={<DisbursedMemberDetail />} />
          <Route path="/merged/loan/details" element={<MergedLoanDetails />} />
          <Route path="/loan/schedule/list" element={<LoanScheduleList />} />
          <Route path="/business/list" element={<BusinessList />} />
          {entities.map(({ path, label, addEditPath }) => (
            <Route key={path}>
              <Route
                path={`/${path}`}
                element={<EntityDetails entity={label} />}
              />
              <Route
                path={`/${addEditPath}`}
                element={<AddEditEntityDetails />}
              />
            </Route>
          ))}
        </Route>
      </Route>
    </Routes>
  );
}
