import React, { useEffect, useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../env";
import axios from "axios";
import "../screens/AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import "../screens/MemberPrint.css";
import Zoom from "react-medium-image-zoom";
import { convertDateStringToDMY, formatISODateToLocalDMY } from "./utils";

const GroupDetailsModal = ({ show, handleClose, groupId, roleId }) => {
  const navigate = useNavigate();
  const [groupDetails, setGroupDetails] = useState(null);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      if (groupId) {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/getModelData/group?filter={"id":${groupId}}&includeDepth=2`
          );
          setGroupDetails(response.data.data[0]);
          // console.log(
          //   "Group Details: " + JSON.stringify(response.data.data[0])
          // );
        } catch (error) {
          console.error("Error fetching group details:", error);
        }
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  const renderImage = (imageName) => {
    return imageName ? (
      <Zoom>
        <img
          src={`${BASE_URL}/images/${imageName}`}
          alt=""
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",

            objectFit: "contain",
          }}
        />
      </Zoom>
    ) : (
      <p></p>
    );
  };

  const DetailTable = ({ details, fields }) => {
    const rows = [];

    for (let i = 0; i < fields.length; i += 3) {
      rows.push(fields.slice(i, i + 3));
    }

    return (
      <table className="details-table ">
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map(({ key, label }) => {
                let value = details[key];

                if (value === null || value === undefined) {
                  value = "";
                }
                return (
                  <Fragment key={key}>
                    <td className="key-container">
                      <p>{label}</p>
                    </td>
                    <td className="key-value-container">
                      <p>{value}</p>
                    </td>
                  </Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const PhotoTable = ({ details, fields }) => {
    return (
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table className="photo-table">
          <thead>
            <tr>
              {fields.map(({ key, label }) => (
                <th key={key}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fields.map(({ key, label }) => (
                <Fragment key={key}>
                  <td>
                    <div className="value-container">
                      {renderImage(details[key])}
                    </div>
                  </td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const groupFields = [
    { key: "leaderName", label: "Leader Name" },
    { key: "mobileNumber", label: "Mobile Number" },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="full-width-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      {groupDetails && (
        <>
          <div className="sub-header">
            <p>Group Details</p>
          </div>

          {groupDetails ? (
            <DetailTable details={groupDetails} fields={groupFields} />
          ) : (
            <p className="paaratext">Group details not available</p>
          )}
          <div className="sub-header">
            <p>Group Photo Details</p>
          </div>
          {groupDetails ? (
            <>
              <PhotoTable
                details={groupDetails}
                fields={[
                  {
                    key: "cgt1Photo",
                    label: "CGT 1 Photo",
                  },
                  {
                    key: "cgt2Photo",
                    label: "CGT 2 Photo",
                  },
                  {
                    key: "grtPhoto",
                    label: "GRT Photo",
                  },
                ]}
              />
              <table className="table">
                <tbody>
                  <tr>
                    <th>CGT 1 Comment</th>
                    <td>{groupDetails.cgt1Comment}</td>
                  </tr>
                  <tr>
                    <th>CGT 2 Comment</th>
                    <td>{groupDetails.cgt2Comment}</td>
                  </tr>
                  <tr>
                    <th>GRT Comment</th>
                    <td>{groupDetails.grtComment}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <p className="paaratext">Group Photos not available</p>
          )}
        </>
      )}
      {roleId === "Credit Manager" && (
        <table className="table table-bordered table-striped">
          <thead className="bg-secondary">
            <tr>
              <th>Member Name</th>
              <th>Aadhar No</th>
              <th>Mobile No</th>
              <th>Loan Amount</th>
              <th>Tenure</th>
              <th>Emi Amount</th>
              <th>Emi date</th>
            </tr>
          </thead>
          <tbody>
            {groupDetails?.fk_group_hasMany_member_details_groupId.map(
              (member) => (
                <Fragment key={member.id}>
                  <tr>
                    <td>{member.memberName}</td>
                    <td>{member.aadharNo}</td>
                    <td>{member.phoneNumber}</td>
                    <td>{member.proposedLoanDetails.loanAmount}</td>
                    <td>{member.proposedLoanDetails.tenureInMonths}</td>
                    <td>{member.proposedLoanDetails.monthlyEmi}</td>
                    <td>{member.proposedLoanDetails.emiDate}</td>
                  </tr>
                </Fragment>
              )
            )}
          </tbody>
        </table>
      )}

      <Modal.Footer>
        <button className="btn btn-danger" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupDetailsModal;
