import React from "react";
import { Form, Image } from "react-bootstrap";

const FormInput = ({
  label,
  type,
  name,
  register,
  errors,
  options = [],
  readOnly,
  handleFileChange,
  existingFileUrl,
  filePreview,
  required,
}) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {required && <span className="text-danger">*</span>}
        {label}
      </Form.Label>
      {type === "select" ? (
        <Form.Select
          isInvalid={!!errors[name]}
          {...register(name)}
          disabled={readOnly}
        >
          <option value="">Select {label}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : type === "checkbox" ? (
        <Form.Check
          type="checkbox"
          label={label}
          {...register(name)}
          isInvalid={!!errors[name]}
          disabled={readOnly}
        />
      ) : type === "file" ? (
        <>
          {!readOnly && (
            // Hide file input entirely if readOnly is true
            <Form.Control
              type="file"
              isInvalid={!!errors[name]}
              {...register(name)}
              onChange={handleFileChange}
            />
          )}

          {/* Display existing or newly selected image */}
          {(filePreview || existingFileUrl) && (
            <div className="mt-2">
              <Image
                src={filePreview || existingFileUrl}
                alt={label}
                fluid
                rounded
                style={{ width: "150px", height: "150px", objectFit: "cover" }}
              />
            </div>
          )}
        </>
      ) : type === "textarea" ? (
        <Form.Control
          as="textarea"
          isInvalid={!!errors[name]}
          {...register(name)}
          readOnly={readOnly}
        />
      ) : (
        <Form.Control
          type={type}
          isInvalid={!!errors[name]}
          {...register(name)}
          readOnly={readOnly}
        />
      )}
      {errors[name] && (
        <Form.Text className="text-danger">{errors[name].message}</Form.Text>
      )}
    </Form.Group>
  );
};

export default FormInput;
