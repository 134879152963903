import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import TablePagination from "../components/TablePagination";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "./ConfirmationModal";

const EntityDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [selectedId, setSelectedId] = useState(null); // State to store the id of the selected entity for deletion
  const entity = location.state?.entity;
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const entityMap = useMemo(
    () => ({
      fundingAgencies: {
        title: "Funding Agencies",
        navigationPath: "addEditFundingAgency",
        apiEndpoint: `${BASE_URL}/api/getModelData/funding_agencies?fields=id,agencyName&notInclude={"agencyName":["own funding"]}`,
        delApiEndPoint: `${BASE_URL}/api/delDetails/`,
        view: true,
        edit: true,
        del: true,
        model: "funding_agencies",
      },
    }),
    []
  );

  useEffect(() => {
    if (entityMap[entity]) {
      setSelectedEntity(entityMap[entity]);
      fetchData(entityMap[entity].apiEndpoint);
    } else {
      console.error("Invalid entity parameter");
    }
  }, [entity, entityMap]);

  const fetchData = async (apiEndpoint) => {
    try {
      const response = await axios.get(apiEndpoint);
      setData(response.data.data);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  const handleAddClick = () => {
    if (selectedEntity) {
      navigate(`/${selectedEntity.navigationPath}`, {
        state: { type: "Add", entity },
      });
    }
  };

  const handleViewClick = (id) => {
    if (selectedEntity) {
      navigate(`/${selectedEntity.navigationPath}`, {
        state: { type: "View", id, entity },
      });
    }
  };

  const handleEditClick = (id) => {
    if (selectedEntity) {
      navigate(`/${selectedEntity.navigationPath}`, {
        state: { type: "Edit", id, entity },
      });
    }
  };

  const handleDelClick = (id) => {
    setSelectedId(id); // Set the id of the entity to be deleted
    setShowModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    if (!selectedEntity || !selectedEntity.delApiEndPoint) {
      console.error("Invalid deletion configuration");
      return;
    }

    const payload = {
      model: selectedEntity.model, // Assuming `entity` is the model name
      where: { id: selectedId }, // Deleting based on `id`
    };

    try {
      const response = await axios.post(selectedEntity.delApiEndPoint, payload);
      if (response.status === 200) {
        toast.success("Record deleted successfully!"); // Show success toast
        fetchData(selectedEntity.apiEndpoint); // Refresh data after deletion
      } else {
        toast.error("Failed to delete record."); // Show error toast
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      toast.error("An error occurred while deleting the record."); // Show error toast
    } finally {
      setShowModal(false); // Close the modal after deletion
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal without deleting
  };

  if (!selectedEntity) {
    return <div>Loading...</div>;
  }

  return (
    <section>
      <div className="d-flex">
        <div style={{ width: "14%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          style={{
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <h1>{selectedEntity.title}</h1>
            <Button onClick={handleAddClick}>Add</Button>
            <div className="m-2">
              <TablePagination
                key={JSON.stringify(data)} // Ensure re-rendering when data changes
                tabledata={data}
                initialRowsPerPage="5"
                showViewOption={selectedEntity.view}
                showEditOption={selectedEntity.edit}
                showDeleteOption={selectedEntity.del}
                onClickView={handleViewClick}
                onClickEdit={handleEditClick}
                onClickDel={handleDelClick}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this record?"
      />
    </section>
  );
};

export default EntityDetails;
