import * as Yup from "yup";
import {
  requiredString,
  emailField,
  // emailFieldRequired,
  // passwordField,
  passwordFieldRequired,
  phoneNumberField,
  phoneNumberFieldRequired,
  // postalCodeField,
  postalCodeFieldRequired,
  requiredFile,
  aadharNumberFieldRequired,
} from "./validationRules";

export const fundingAgencyDetailsValidationSchema = Yup.object().shape({
  agencyName: requiredString("Agency Name"),
});
