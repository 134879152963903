import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Form, Row, Col } from "react-bootstrap";
import {
  getFirstAndLastEmiDates,
  calculateEmiAmount,
} from "../components/utils";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";
import { useAdminAuth } from "../components/AdminAuthProvider";

const InsuranceReport = () => {
  const { adminUser } = useAdminAuth();
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginationData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async (id) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getInsuranceReportData`,
          { params: { id } }
        );
        // console.log(
        //   "MasterData response.data: " + JSON.stringify(response.data)
        // );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData(adminUser.user.id);
  }, [adminUser.user.id]);

  // Automatically filter data when fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filtered = reportData
        .map((item) => {
          // Calculate emiMonthsPaid based on receiptsDetails array
          const emiMonthsPaid = item.receiptsDetails
            ? item.receiptsDetails.filter(
                (receipt) =>
                  new Date(receipt.emiDate) >= new Date(fromDate) &&
                  new Date(receipt.emiDate) <= new Date(toDate) &&
                  receipt.status === "paid"
              ).length
            : 0;

          return {
            ...item,
            emiMonthsPaid, // Add emiMonthsPaid to each item
          };
        })
        .filter((item) => {
          // Further filter data based on branchManagerStatusUpdatedAt within the date range
          const updatedAt = new Date(item.branchManagerStatusUpdatedAt);
          return (
            updatedAt >= new Date(fromDate) && updatedAt <= new Date(toDate)
          );
        });

      setFilteredData(filtered);
    } else {
      setFilteredData([]); // Show no data if dates are not selected
    }
  }, [fromDate, toDate, reportData]);

  // Define columns with label and render logic
  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Branch Code", render: (data) => data.branchCode },
    {
      label: "Sub Category",
      render: (data) => data.businessDetails.natureOfBusiness,
    },
    { label: "Loan Account No", render: (data) => data.loanId },
    { label: "Serial Number", render: (data) => data.loanCycle },
    { label: "Member Name", render: (data) => data.memberName },
    {
      label: "Member Guardian Name",
      render: (data) => data.familyMember.coApplicantName,
    },
    { label: "Member DOB", render: (data) => data.dateOfBirth },
    { label: "Gender", render: (data) => data.gender },
    { label: "Document Type", render: (data) => "Aadhar" },
    { label: "Document Number", render: (data) => data.aadharNo },
    { label: "Relationship from Primary", render: (data) => "Self" },
    {
      label: "Occupation",
      render: (data) => data.businessDetails.natureOfBusiness,
    },
    { label: "Mobile No", render: (data) => data.phoneNumber },
    { label: "Email", render: (data) => "" },
    {
      label: "Nominee Name",
      render: (data) => data.familyMember.coApplicantName,
    },
    {
      label: "Nominee DOB",
      render: (data) => data.familyMember.dateOfBirth,
    },
    {
      label: "Nominee Age",
      render: (data) => {
        const dateOfBirth = new Date(data.familyMember.dateOfBirth); // Parse the dateOfBirth
        const today = new Date(); // Current date
        let age = today.getFullYear() - dateOfBirth.getFullYear(); // Calculate the difference in years

        // Adjust if the birthdate hasn't occurred this year
        const hasHadBirthday =
          today.getMonth() > dateOfBirth.getMonth() ||
          (today.getMonth() === dateOfBirth.getMonth() &&
            today.getDate() >= dateOfBirth.getDate());
        if (!hasHadBirthday) {
          age--;
        }

        return age; // Return the calculated age
      },
    },
    {
      label: "Nominee Gender",
      render: (data) => data.familyMember.gender,
    },
    {
      label: "Nominee Relation",
      render: (data) => data.familyMember.relation,
    },
    {
      label: "Nominee Minor",
      render: (data) => {
        const dateOfBirth = new Date(data.familyMember.dateOfBirth); // Parse the dateOfBirth
        const today = new Date(); // Current date
        let age = today.getFullYear() - dateOfBirth.getFullYear(); // Calculate the difference in years

        // Adjust if the birthdate hasn't occurred this year
        const hasHadBirthday =
          today.getMonth() > dateOfBirth.getMonth() ||
          (today.getMonth() === dateOfBirth.getMonth() &&
            today.getDate() >= dateOfBirth.getDate());
        if (!hasHadBirthday) {
          age--;
        }

        return age > 18 ? "NO" : "YES";
      },
    },
    { label: "Nominee Guardian Name", render: (data) => data.memberName },
    {
      label: "Nominee Guardian Age",
      render: (data) => {
        const dateOfBirth = new Date(data.dateOfBirth); // Parse the dateOfBirth
        const today = new Date(); // Current date
        let age = today.getFullYear() - dateOfBirth.getFullYear(); // Calculate the difference in years

        // Adjust if the birthdate hasn't occurred this year
        const hasHadBirthday =
          today.getMonth() > dateOfBirth.getMonth() ||
          (today.getMonth() === dateOfBirth.getMonth() &&
            today.getDate() >= dateOfBirth.getDate());
        if (!hasHadBirthday) {
          age--;
        }

        return age; // Return the calculated age
      },
    },
    {
      label: "Nominee Guardian Relationship with Nominee",
      render: (data) => {
        const { relation, gender } = data.familyMember;

        if (relation === "Son") {
          return gender === "male" ? "Father" : "Mother";
        } else if (relation === "Daughter") {
          return gender === "male" ? "Father" : "Mother";
        } else if (relation === "Spouse") {
          return "Spouse";
        } else if (relation === "Father") {
          return gender === "male" ? "Son" : "Daughter";
        } else if (relation === "Mother") {
          return gender === "male" ? "Son" : "Daughter";
        } else if (relation === "Brother") {
          return gender === "male" ? "Brother" : "Sister";
        } else if (relation === "Father-in-Law") {
          return gender === "male" ? "Son-in-Law" : "Daughter-in-Law";
        } else if (relation === "Mother-in-Law") {
          return gender === "male" ? "Son-in-Law" : "Daughter-in-Law";
        }

        // Default return if no match is found
        return "Unknown";
      },
    },
    { label: "Master Policy No", render: (data) => "" },
    {
      label: "Loan Amount",
      render: (data) => data.sanctionedLoanAmountBySanctionCommittee,
    },
    {
      label: "Start Date",
      render: (data) => {
        const { firstEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return firstEmiDate ? firstEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Premium",
      render: (data) => (data.isLoanInsured ? data.insuranceAmount : 0),
    },
    {
      label: "Sum Insured Loan Amount",
      render: (data) => data.sanctionedLoanAmountBySanctionCommittee,
    },
    {
      label: "Tenure In Months",
      render: (data) => data.proposedLoanDetails.tenureInMonths,
    },
    { label: "Loan Type", render: (data) => "Business Loan" },
    {
      label: "Address1",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Address2", render: (data) => "" },
    { label: "City", render: (data) => data.permanentDistrict },
    { label: "State", render: (data) => data.state },
    { label: "Country", render: (data) => "India" },
    { label: "Pincode", render: (data) => data.permanentPincode },
    { label: "Constructs", render: (data) => "" },
    { label: "otherProduct1Applicable", render: (data) => "" },
    { label: "otherProduct1SumInsured", render: (data) => "" },
    { label: "paymentDate", render: (data) => "" },
    { label: "dateOfDisbursement", render: (data) => "" },
    // Add more columns as needed with custom render logic
  ];

  return (
    <div className="dashboard-over">
      <div className="d-flex" style={{ width: "100vw" }}>
        <div style={{ width: "13%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          className="container-fluid"
          style={{
            // overflowY: "scroll",
            width: "87%",
            height: "100vh",
            // scrollbarWidth: "none",
          }}
        >
          <div style={{ height: "23%", zIndex: "5", position: "relative" }}>
            <h1>Insurance Report</h1>
            <div style={{ position: "absolute", width: "100%" }}>
              <Row style={{ fontWeight: "500" }}>
                <Col lg={2}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      value={fromDate}
                      onChange={(event) => setFromDate(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      value={toDate}
                      onChange={(event) => setToDate(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ height: "69%" }}>
            <DataTable
              columns={columns}
              data={paginationData}
              reportName="InsuranceReport"
              excelData={filteredData}
            />
          </div>

          <div
            style={{
              height: "8%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceReport;
