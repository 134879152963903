import React, { useState, useEffect, useCallback } from "react";
import ApproveModal from "./ApproveModal";
import { BASE_URL } from "../env";
import axios from "axios";
import { Button } from "react-bootstrap";
import RejectModal from "./RejectModal";
import ApproveJlgCreditManagerModal from "./ApproveJlgCreditManagerModal";
import { TiTick } from "react-icons/ti";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import ApproveMisModal from "./ApproveMisModal";
import { useNavigate } from "react-router-dom";
import RetriggerJlgModal from "./RetriggerJlgModal";
import ApproveJlgAMModal from "./ApproveJlgAMModal";
import ApproveSanctionCommitteeModal from "./ApproveSanctionCommitteeModal";

const ActionButtonsJlg = ({
  roleId,
  center,
  member,
  group,
  updateList,
  managerId,
  proposedLoanAmount,
}) => {
  const navigate = useNavigate();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showApproveMisModal, setShowApproveMisModal] = useState(false);
  const [retriggerToRole, setRetriggerToRole] = useState("");
  const [showRetriggerModal, setShowRetriggerModal] = useState(false);
  const [showApproveCreditManagerModal, setShowApproveCreditManagerModal] =
    useState(false);
  const [showApproveAmModal, setShowApproveAmModal] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [message, setMessage] = useState("");
  const [comment, setComment] = useState("");
  const [centerUnfilteredData, setCenterUnfilteredData] = useState([]);
  const [
    showApproveSanctionCommitteeModal,
    setShowApproveSanctionCommitteeModal,
  ] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");

  const fetchCenterDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/getModelData/center?includeDepth=3&filter={"id":${center.id}}`
      );
      setCenterUnfilteredData(response.data.data[0]);
    } catch (error) {
      console.log("Error fetching center details:", error);
    }
  }, [center]);

  useEffect(() => {
    fetchCenterDetails();
  }, [fetchCenterDetails]);

  const handleSubmit = async (memberId, msg, modalName) => {
    setMemberId(memberId);
    setMessage(msg);
    if (modalName === "submit") {
      setShowApproveModal(true);
    } else if (modalName === "retrigger") {
      setShowRetriggerModal(true);
    } else if (modalName === "submitMis") {
      setShowApproveMisModal(true);
    } else if (modalName === "submitCreditManager") {
      setShowApproveCreditManagerModal(true);
    } else if (modalName === "approveSanctionCommittee") {
      setShowApproveSanctionCommitteeModal(true);
    } else if (modalName === "submitAm") {
      setShowApproveAmModal(true);
    }
  };

  const currentISTDateTime = new Date().toISOString();

  const handleApproveConfirmMis = async () => {
    try {
      const payload = {};
      payload.id = memberId;
      payload.model = "member_details";
      payload.type = "Edit";
      const formattedMessage =
        "\n" +
        comment +
        "\n---By " +
        roleId +
        "\n-- " +
        currentISTDateTime +
        "\n******** ";
      if (roleId === "MIS") {
        payload.misStatus = "submitted";
        payload.misMessage = formattedMessage;
        payload.misStatusUpdatedAt = currentISTDateTime;
      }
      // if (roleId === "Credit Manager") {
      //   payload.creditManagerStatus = "submitted";
      //   payload.creditManagerMessage = formattedMessage;
      //   payload.creditManagerStatusUpdatedAt = currentISTDateTime;
      // }
      if (roleId === "Branch Manager") {
        payload.branchManagerStatus = "disbursed";
        payload.branchManagerMessage = formattedMessage;
        payload.branchManagerStatusUpdatedAt = currentISTDateTime;
      }

      await axios.post(`${BASE_URL}/api/addDetails`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowApproveMisModal(false);
    }
  };

  const handleCloseApproveMis = async () => {
    setShowApproveMisModal(false);
  };

  const handleRetriggerConfirm = async () => {
    try {
      const payload = {};
      payload.id = memberId;
      payload.model = "member_details";
      payload.type = "Edit";
      const formattedMessage =
        "\n" +
        comment +
        "\n---By " +
        roleId +
        "\n-- " +
        currentISTDateTime +
        "\n******** ";
      if (retriggerToRole === "Customer Relationship Officer") {
        payload.fieldManagerStatus =
          roleId === "Accounts Manager"
            ? "retriggerToEditBankDetails"
            : "retrigger";
        payload.fieldManagerMessage = formattedMessage;
        payload.fieldManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Branch Manager") {
        payload.branchManagerStatus = "retrigger";
        payload.branchManagerMessage = formattedMessage;
        payload.branchManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "MIS") {
        payload.misStatus = "retrigger";
        payload.misMessage = formattedMessage;
        payload.misStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Credit Manager") {
        payload.creditManagerStatus = "retrigger";
        payload.creditManagerMessage = formattedMessage;
        payload.creditManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Sanction Committee") {
        payload.sanctionCommitteeStatus = "retrigger";
        payload.sanctionCommitteeMessage = formattedMessage;
        payload.sanctionCommitteeStatusUpdatedAt = currentISTDateTime;
      }

      await axios.post(`${BASE_URL}/api/addDetails`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowRetriggerModal(false);
    }
  };

  const handleCloseRetrigger = async () => {
    setShowRetriggerModal(false);
  };

  const handleApproveConfirmCreditManager = async () => {
    updateList();
    setShowApproveCreditManagerModal(false);
  };

  const handleCloseApproveCreditManager = async () => {
    setShowApproveCreditManagerModal(false);
  };

  const handleApproveConfirmSanctionCommittee = async () => {
    try {
      const payload = {};

      if (roleId === "Sanction Committee") {
        payload.memberId = memberId;
        payload.sanctionCommitteeStatus = "approved";
        payload.sanctionCommitteeStatusUpdatedAt = currentISTDateTime;
        payload.sanctionedLoanAmountBySanctionCommittee = loanAmount;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowApproveSanctionCommitteeModal(false);
    }
  };

  const handleCloseApproveSanctionCommittee = async () => {
    setShowApproveSanctionCommitteeModal(false);
  };

  const handleApproveConfirmAm = () => {
    updateList();
    setShowApproveAmModal(false);
  };
  const handleCloseAm = () => {
    updateList();
    setShowApproveAmModal(false);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {roleId === "MIS" &&
        member.fieldManagerStatus === "submitted" &&
        member.branchManagerStatus === "submitted" &&
        ["pending", "retrigger"].includes(member.misStatus) &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return (
                        member.fieldManagerStatus === "submitted" &&
                        (member.branchManagerStatus === "submitted" ||
                          member.branchManagerStatus === "disbursed")
                      );
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })() && (
          <div className="mb-1">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  member.id,
                  "Are you sure you want to Submit?",
                  "submitMis"
                )
              }
            >
              <TiTick size={20} /> Submit
            </Button>
          </div>
        )}
      {roleId === "Branch Manager" &&
        // Add validation for groups and members
        member.fieldManagerStatus === "submitted" &&
        member.misStatus === "submitted" &&
        member.creditManagerStatus === "submitted" &&
        member.sanctionCommitteeStatus === "approved" &&
        member.isAttendedBank &&
        ["submitted", "retrigger"].includes(member.branchManagerStatus) &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return (
                        member.fieldManagerStatus === "submitted" &&
                        ["submitted", "retrigger", "disbursed"].includes(
                          member.branchManagerStatus
                        ) &&
                        member.misStatus === "submitted" &&
                        member.creditManagerStatus === "submitted" &&
                        member.sanctionCommitteeStatus === "approved" &&
                        member.isAttendedBank &&
                        member.processingCharge &&
                        Array.isArray(
                          member.branchManagerVerificationPhotosDetails
                        ) &&
                        member.branchManagerVerificationPhotosDetails.length > 0
                      );
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })() && (
          <div className="mb-1">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  member.id,
                  "Are you sure you want to Disburse?",
                  "submitMis"
                )
              }
            >
              <TiTick size={20} /> Disburse
            </Button>
          </div>
        )}

      {((roleId === "Branch Manager" &&
        member.fieldManagerStatus === "submitted" &&
        member.branchManagerStatus === "pending" &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return member.fieldManagerStatus === "submitted";
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })()) ||
        (roleId === "Branch Manager" &&
          member.fieldManagerStatus === "submitted" &&
          member.branchManagerStatus === "submitted" &&
          member.misStatus === "submitted" &&
          member.creditManagerStatus === "submitted" &&
          member.sanctionCommitteeStatus === "approved" &&
          member.accountManagerStatus === "pending" &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
          (() => {
            const validGroups =
              centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
                (group) => {
                  const validMembers =
                    group.fk_group_hasMany_member_details_groupId.filter(
                      (member) => {
                        return (
                          member.fieldManagerStatus === "submitted" &&
                          ["submitted", "disbursed"].includes(
                            member.branchManagerStatus
                          ) &&
                          member.misStatus === "submitted" &&
                          ["submitted", "retrigger"].includes(
                            member.creditManagerStatus
                          ) &&
                          member.sanctionCommitteeStatus === "approved"
                        );
                      }
                    );
                  return validMembers.length >= 4 && validMembers.length <= 6;
                }
              );
            return validGroups.length >= 1 && validGroups.length <= 6;
          })()) ||
        (roleId === "MIS" &&
          member.fieldManagerStatus === "submitted" &&
          member.branchManagerStatus === "submitted" &&
          member.misStatus === "pending" &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
          (() => {
            const validGroups =
              centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
                (group) => {
                  const validMembers =
                    group.fk_group_hasMany_member_details_groupId.filter(
                      (member) => {
                        return (
                          member.fieldManagerStatus === "submitted" &&
                          (member.branchManagerStatus === "submitted" ||
                            member.branchManagerStatus === "disbursed")
                        );
                      }
                    );
                  return validMembers.length >= 4 && validMembers.length <= 6;
                }
              );
            return validGroups.length >= 1 && validGroups.length <= 6;
          })()) ||
        (roleId === "Credit Manager" &&
          member.fieldManagerStatus === "submitted" &&
          member.branchManagerStatus === "submitted" &&
          member.misStatus === "submitted" &&
          member.creditManagerStatus === "pending" &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
          (() => {
            const validGroups =
              centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
                (group) => {
                  const validMembers =
                    group.fk_group_hasMany_member_details_groupId.filter(
                      (member) => {
                        return (
                          member.fieldManagerStatus === "submitted" &&
                          (member.branchManagerStatus === "submitted" ||
                            member.branchManagerStatus === "disbursed") &&
                          member.misStatus === "submitted"
                        );
                      }
                    );
                  return validMembers.length >= 4 && validMembers.length <= 6;
                }
              );
            return validGroups.length >= 1 && validGroups.length <= 6;
          })()) ||
        (roleId === "Sanction Committee" &&
          member.fieldManagerStatus === "submitted" &&
          member.branchManagerStatus === "submitted" &&
          member.misStatus === "submitted" &&
          member.creditManagerStatus === "submitted" &&
          member.sanctionCommitteeStatus === "pending" &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
          (() => {
            const validGroups =
              centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
                (group) => {
                  const validMembers =
                    group.fk_group_hasMany_member_details_groupId.filter(
                      (member) => {
                        return (
                          member.fieldManagerStatus === "submitted" &&
                          (member.branchManagerStatus === "submitted" ||
                            member.branchManagerStatus === "disbursed") &&
                          member.misStatus === "submitted" &&
                          member.creditManagerStatus === "submitted"
                        );
                      }
                    );
                  return validMembers.length >= 4 && validMembers.length <= 6;
                }
              );
            return validGroups.length >= 1 && validGroups.length <= 6;
          })()) ||
        (roleId === "Accounts Manager" &&
          member.fieldManagerStatus === "submitted" &&
          member.branchManagerStatus === "disbursed" &&
          member.misStatus === "submitted" &&
          member.creditManagerStatus === "submitted" &&
          member.sanctionCommitteeStatus === "approved" &&
          member.accountManagerStatus === "pending" &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
          centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
          (() => {
            const validGroups =
              centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
                (group) => {
                  const validMembers =
                    group.fk_group_hasMany_member_details_groupId.filter(
                      (member) => {
                        return (
                          member.fieldManagerStatus === "submitted" &&
                          member.branchManagerStatus === "disbursed" &&
                          member.misStatus === "submitted" &&
                          member.creditManagerStatus === "submitted" &&
                          member.sanctionCommitteeStatus === "approved"
                        );
                      }
                    );
                  return validMembers.length >= 4 && validMembers.length <= 6;
                }
              );
            return validGroups.length >= 1 && validGroups.length <= 6;
          })())) && (
        <div className="mb-2">
          <Button
            variant="warning"
            onClick={() =>
              handleSubmit(
                member.id,
                "Are you sure you want to Retrigger?",
                "retrigger"
              )
            }
          >
            <FaArrowRotateLeft size={17} />
          </Button>
        </div>
      )}
      {roleId === "Credit Manager" &&
        member.fieldManagerStatus === "submitted" &&
        member.branchManagerStatus === "submitted" &&
        member.misStatus === "submitted" &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return (
                        member.fieldManagerStatus === "submitted" &&
                        (member.branchManagerStatus === "submitted" ||
                          member.branchManagerStatus === "disbursed") &&
                        member.misStatus === "submitted"
                      );
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })() &&
        ["pending", "retrigger"].includes(member.creditManagerStatus) && (
          <div className="mb-1">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  member.id,
                  "Are you sure you want to Submit?",
                  "submitCreditManager"
                )
              }
            >
              <TiTick size={20} /> Submit
            </Button>
          </div>
        )}
      {roleId === "Sanction Committee" &&
        member.fieldManagerStatus === "submitted" &&
        member.branchManagerStatus === "submitted" &&
        member.misStatus === "submitted" &&
        member.creditManagerStatus === "submitted" &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return (
                        member.fieldManagerStatus === "submitted" &&
                        (member.branchManagerStatus === "submitted" ||
                          member.branchManagerStatus === "disbursed") &&
                        member.misStatus === "submitted" &&
                        member.creditManagerStatus === "submitted"
                      );
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })() &&
        ["pending", "retrigger"].includes(member.sanctionCommitteeStatus) && (
          <div className="mb-1">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  member.id,
                  "Are you sure you want to Approve?",
                  "approveSanctionCommittee"
                )
              }
            >
              <TiTick size={20} /> Submit
            </Button>
          </div>
        )}
      {roleId === "Accounts Manager" &&
        member.fieldManagerStatus === "submitted" &&
        member.branchManagerStatus === "disbursed" &&
        member.misStatus === "submitted" &&
        member.creditManagerStatus === "submitted" &&
        member.sanctionCommitteeStatus === "approved" &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length >= 1 &&
        centerUnfilteredData?.fk_center_hasMany_group_centerId?.length <= 6 &&
        (() => {
          const validGroups =
            centerUnfilteredData?.fk_center_hasMany_group_centerId.filter(
              (group) => {
                const validMembers =
                  group.fk_group_hasMany_member_details_groupId.filter(
                    (member) => {
                      return (
                        member.fieldManagerStatus === "submitted" &&
                        member.branchManagerStatus === "disbursed" &&
                        member.misStatus === "submitted" &&
                        member.creditManagerStatus === "submitted" &&
                        member.sanctionCommitteeStatus === "approved"
                      );
                    }
                  );
                return validMembers.length >= 4 && validMembers.length <= 6;
              }
            );
          return validGroups.length >= 1 && validGroups.length <= 6;
        })() &&
        ["pending", "retrigger"].includes(member.accountManagerStatus) && (
          <div className="mb-1">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  member.id,
                  "Are you sure you want to Submit?",
                  "submitAm"
                )
              }
            >
              <TiTick size={20} /> Submit
            </Button>
          </div>
        )}
      {showRetriggerModal && (
        <RetriggerJlgModal
          show={showRetriggerModal}
          handleClose={handleCloseRetrigger}
          handleConfirm={handleRetriggerConfirm}
          message={message}
          comment={comment}
          setComment={setComment}
          retriggerToRole={retriggerToRole}
          setRetriggerToRole={setRetriggerToRole}
          roleId={roleId}
          item={member}
        />
      )}
      {showApproveMisModal && (
        <ApproveMisModal
          show={showApproveMisModal}
          handleClose={handleCloseApproveMis}
          handleConfirm={handleApproveConfirmMis}
          message={message}
          comment={comment}
          setComment={setComment}
        />
      )}
      {showApproveCreditManagerModal && (
        <ApproveJlgCreditManagerModal
          show={showApproveCreditManagerModal}
          handleClose={handleCloseApproveCreditManager}
          handleConfirm={handleApproveConfirmCreditManager}
          memberId={memberId}
          creditManagerId={managerId}
          proposedLoanAmount={proposedLoanAmount}
        />
      )}
      {showApproveSanctionCommitteeModal && (
        <ApproveSanctionCommitteeModal
          show={showApproveSanctionCommitteeModal}
          handleClose={handleCloseApproveSanctionCommittee}
          handleConfirm={handleApproveConfirmSanctionCommittee}
          message={message}
          loanAmount={loanAmount}
          setLoanAmount={setLoanAmount}
          proposedLoanAmount={proposedLoanAmount}
        />
      )}
      {showApproveAmModal && (
        <ApproveJlgAMModal
          show={showApproveAmModal}
          handleClose={handleCloseAm}
          handleConfirm={handleApproveConfirmAm}
          message={message}
          comment={comment}
          setComment={setComment}
          center={center}
          group={group}
          member={member}
        />
      )}
    </div>
  );
};

export default ActionButtonsJlg;
