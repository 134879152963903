import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";

const weekNoOptions = [
  { value: "1", label: "1st Week" },
  { value: "2", label: "2nd Week" },
  { value: "3", label: "3rd Week" },
  { value: "4", label: "4th Week" },
];

const weekDayOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
];

const UpdateMeetingDetailsByBmModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  item,
}) => {
  const initialWeekNo = item.bmMeetingDayOrder
    ? item.bmMeetingDayOrder.split(",")[0]
    : "";
  const initialWeekDay = item.bmMeetingDayOrder
    ? item.bmMeetingDayOrder.split(",")[1]
    : "";

  const [weekNo, setWeekNo] = useState(initialWeekNo);
  const [weekDay, setWeekDay] = useState(initialWeekDay);
  const [bmMeetingTime, setBmMeetingTime] = useState(item.bmMeetingTime || "");
  const [validationError, setValidationError] = useState("");

  const bmMeetingDayOrder = weekNo && weekDay ? `${weekNo},${weekDay}` : "";

  const handleClick = () => {
    if (!weekNo || !weekDay || !bmMeetingTime) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm(bmMeetingDayOrder, bmMeetingTime);
    }
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    if (timeRegex.test(time)) {
      setBmMeetingTime(time);
      setValidationError("");
    } else {
      setValidationError("Invalid time format. Use hh:mm:ss.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Meeting Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <Form.Group>
          <Form.Label>Week No</Form.Label>
          <Select
            options={weekNoOptions}
            value={weekNoOptions.find((option) => option.value === weekNo)}
            onChange={(selected) => setWeekNo(selected.value)}
            placeholder="Select Week No"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Week Day</Form.Label>
          <Select
            options={weekDayOptions}
            value={weekDayOptions.find((option) => option.value === weekDay)}
            onChange={(selected) => setWeekDay(selected.value)}
            placeholder="Select Week Day"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Meeting Time (hh:mm:ss)</Form.Label>
          <Form.Control
            type="text"
            value={bmMeetingTime}
            onChange={handleTimeChange}
            placeholder="Enter meeting time (e.g., 14:30:00)"
          />
        </Form.Group>
        {validationError && <p style={{ color: "red" }}>{validationError}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateMeetingDetailsByBmModal;
