import axios from "axios";
import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../env";

const ApproveJlgAMModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  comment,
  setComment,
  member,
  group,
}) => {
  const [fedLanNo, setFedLanNo] = useState("");
  const [validationError, setValidationError] = useState("");

  const currentISTDateTime = new Date().toISOString();
  const handleClick = async () => {
    if (fedLanNo === "" || !fedLanNo) {
      setValidationError("Please fill fedLanNo");
      return;
    } else {
      setValidationError("");
    }
    try {
      const payload = {};
      payload.id = member.id;
      payload.model = "member_details";
      payload.type = "Edit";
      const formattedMessage =
        "\n" +
        comment +
        "\n---By " +
        "Accounts Manager" +
        "\n-- " +
        currentISTDateTime +
        "\n******** ";
      payload.accountManagerStatus = "submitted";
      payload.accountManagerMessage = formattedMessage;
      payload.accountManagerStatusUpdatedAt = currentISTDateTime;
      payload.fedLanNo = fedLanNo;
      await axios.post(`${BASE_URL}/api/addDetails`, {
        ...payload,
      });
    } catch (error) {
      console.log("Error updating list:", error);
    }
    handleConfirm();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="full-width-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <table className="table table-bordered table-striped my-5">
          <thead className="bg-secondary">
            <tr>
              <th>Leader Name</th>
              <th>Member Name</th>
              <th>Loan Amount</th>
              <th>Tenure</th>
              <th>Phone No</th>
              <th>Emi Amount</th>
              <th>Federal LAN No</th>
            </tr>
          </thead>
          <tbody>
            {member.isAttendedBank && member.processingCharge && (
              <tr key={member.id}>
                <td>{group.leaderName}</td>
                <td>{member.memberName}</td>
                <td>{member.proposedLoanDetails?.loanAmount}</td>
                <td>{member.proposedLoanDetails?.tenureInMonths}</td>
                <td>{member.phoneNumber}</td>
                <td>{member.securityDeposit}</td>
                <td>
                  <Form.Control
                    type="text"
                    value={fedLanNo}
                    onChange={(event) => setFedLanNo(event.target.value)}
                    required
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Form.Group>
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter Comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            required
          />
        </Form.Group>

        <p className="text-danger">{validationError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveJlgAMModal;
