import React, { useState } from "react";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { useNavigate } from "react-router-dom"; // useLocation
import {
  FaUserCheck,
  FaUserClock,
  FaUserLock,
  FaUserTimes,
} from "react-icons/fa";
import { BiSolidUserDetail } from "react-icons/bi";
// import { FaUserCheck } from "react-icons/fa6";
import { BiSolidDashboard } from "react-icons/bi";
import { RiLoginCircleLine } from "react-icons/ri";
// import { MdDangerous } from "react-icons/md";
import { PiGitBranchBold } from "react-icons/pi";
// import { GiTakeMyMoney } from "react-icons/gi";
import { MdArrowDropDown } from "react-icons/md";
// import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { TbReport, TbReportAnalytics } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { BsDiagram3 } from "react-icons/bs";
import { RiNodeTree } from "react-icons/ri";
import { GrMapLocation } from "react-icons/gr";
import { TbPasswordUser } from "react-icons/tb";
import { GoPasskeyFill } from "react-icons/go";

const Sidebar = ({ openSidebarToggle, OpenSidebar }) => {
  const { adminUser, adminLogout } = useAdminAuth();
  // console.log("adminUser: " + JSON.stringify(adminUser));
  const navigate = useNavigate();
  // const location = useLocation();

  const [isMasterDropdownOpen, setMasterDropdownOpen] = useState(false);
  const [isBlDropdownOpen, setBlDropdownOpen] = useState(false);
  const [isJlgDropdownOpen, setJlgDropdownOpen] = useState(false);
  const [isReportsDropdownOpen, setReportsDropdownOpen] = useState(false);
  const [isJlgReportsDropdownOpen, setJlgReportsDropdownOpen] = useState(false);
  const [isActive, setIsActive] = useState("Dashboard");

  const isSuperAdmin = adminUser && adminUser.userType === "superadmin";
  const isDeveloper = adminUser && adminUser.userType === "developer";
  const isBranchManager = adminUser && adminUser.userType === "Branch Manager";
  // const isCreditOfficer = adminUser && adminUser.userType === "Credit Officer";
  const isMis = adminUser && adminUser.userType === "MIS";
  // const isCreditManager = adminUser && adminUser.userType === "Credit Manager";
  // const isSanctionCommittee =
  //   adminUser && adminUser.userType === "Sanction Committee";
  // const isAccountsManager =
  //   adminUser && adminUser.userType === "Accounts Manager";

  const handleLoginCredentialsClick = () => {
    navigate("/logincredential");
  };

  const handleLogoutClick = () => {
    adminLogout();
    navigate("/");
  };

  const handleDashboard = () => {
    navigate("/Dashboard");
  };

  const toggleMasterDropdown = () => {
    setMasterDropdownOpen(!isMasterDropdownOpen);
  };
  const toggleBlDropdown = () => {
    setBlDropdownOpen(!isBlDropdownOpen);
  };
  const toggleJlgDropdown = () => {
    setJlgDropdownOpen(!isJlgDropdownOpen);
  };
  const toggleReportsDropdown = () => {
    setReportsDropdownOpen(!isReportsDropdownOpen);
  };

  const toggleJlgReportsDropdown = () => {
    setJlgReportsDropdownOpen(!isJlgReportsDropdownOpen);
  };

  // const isActive = (path) => location.pathname === path;

  return (
    <section>
      <aside
        id="sidebar"
        className={
          openSidebarToggle ? "sidebar-responsive" : "sidebar-responsive-set"
        }
      >
        <IoClose
          className="icon closeIconSideNav"
          onClick={OpenSidebar}
          size={24}
        />
        <div className="sidebar-title text-center" onClick={handleDashboard}>
          <div className="profile-sidebar">
            <img
              src={require("../assets/vv-logo.png")}
              className="img-radius"
              alt="User-Profile-pic"
            />
          </div>
          <div className="sidebar-brand">
            <span>{adminUser.user.username}</span>
          </div>
        </div>

        <div className="sidebar-list">
          <p
            className={`sidebar-list-item ${
              isActive === "Dashboard" ? "active" : ""
            }`}
            onClick={() => {
              setIsActive("Dashboard");
              navigate("/Dashboard");
            }}
          >
            <span className="gap-2">
              <BiSolidDashboard className="icon" /> Dashboard
            </span>
          </p>
          {(isSuperAdmin || isDeveloper) && (
            <>
              <div className=" sidebar-list-item">
                <div
                  className=" sidebarhover"
                  style={{
                    borderBottom: "0px",
                    padding: "10px",
                    fontSize: "14px",
                  }}
                >
                  <div
                    onClick={toggleMasterDropdown}
                    style={{
                      borderBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="gap-2 ">
                      <FaUserLock className="icon" /> Master
                    </span>

                    <span>
                      <MdArrowDropDown
                        className="icon"
                        style={{ display: "flex", alignSelf: "flex-end" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {isMasterDropdownOpen && (
                <ul
                  style={{ listStyle: "none" }}
                  className="nested-list-master"
                >
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Region" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Region");
                      navigate("/region");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <GrMapLocation className="icon" /> Region
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Division" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Division");
                      navigate("/division");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <RiNodeTree className="icon" /> Division
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Branch" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Branch");
                      navigate("/branch");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <BsDiagram3 className="icon" /> Branch
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Login Credentials" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Login Credentials");
                      handleLoginCredentialsClick();
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <TbPasswordUser className="icon" /> Login Credentials
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Permissions" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Permissions");
                      navigate("/permissions");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <GoPasskeyFill className="icon" /> Permissions
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Transfer Cro" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Transfer Cro");
                      navigate("/transferCro");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <GoPasskeyFill className="icon" /> Transfer Cro
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Funding Agencies" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Funding Agencies");
                      navigate("/fundingAgencies", {
                        state: { entity: "fundingAgencies" },
                      });
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <BsDiagram3 className="icon" /> Funding Agencies
                    </span>
                  </li>
                </ul>
              )}
            </>
          )}

          <div className=" sidebar-list-item">
            <div
              className=" sidebarhover"
              style={{
                borderBottom: "0px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              <div
                onClick={toggleBlDropdown}
                style={{
                  borderBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="gap-2 ">
                  <FaUserLock className="icon" /> BL
                </span>

                <span>
                  <MdArrowDropDown
                    className="icon"
                    style={{ display: "flex", alignSelf: "flex-end" }}
                  />
                </span>
              </div>
            </div>
          </div>
          {isBlDropdownOpen && (
            <ul style={{ listStyle: "none" }} className="nested-list-master">
              {(isSuperAdmin || isDeveloper) && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Member Details" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Member Details");
                    navigate("/memberdetails", {
                      state: {
                        manager: "",
                        filterStatus: "",
                        isActive: "Member Details",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Member Details
                  </span>
                </li>
              )}
              <li
                className={`nested-list-master-item ${
                  isActive === "Approved" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Approved");
                  navigate("/memberdetails", {
                    state: {
                      filterStatus: "approved",
                      isActive: "Approved",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <FaUserCheck className="icon" /> Approved
                </span>
              </li>
              {!(isSuperAdmin || isDeveloper) && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Retrigger" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Retrigger");
                    navigate("/memberdetails", {
                      state: {
                        filterStatus: "retrigger",
                        isActive: "Retrigger",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Retrigger
                  </span>
                </li>
              )}
              {!(isSuperAdmin || isDeveloper) && (isBranchManager || isMis) && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Disbursed" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Disbursed");
                    navigate("/memberdetails", {
                      state: {
                        filterStatus: "disbursed",
                        isActive: "Disbursed",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Disburse
                  </span>
                </li>
              )}
              <li
                className={`nested-list-master-item ${
                  isActive === "Pending" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Pending");
                  navigate("/memberdetails", {
                    state: {
                      filterStatus: "pending",
                      isActive: "Pending",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <FaUserClock className="icon" /> Pending
                </span>
              </li>
              <li
                className={`nested-list-master-item ${
                  isActive === "Rejected" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Rejected");
                  navigate("/memberdetails", {
                    state: {
                      filterStatus: "rejected",
                      isActive: "Rejected",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <FaUserTimes className="icon" /> Rejected
                </span>
              </li>
            </ul>
          )}
          <div className=" sidebar-list-item">
            <div
              className=" sidebarhover"
              style={{
                borderBottom: "0px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              <div
                onClick={toggleJlgDropdown}
                style={{
                  borderBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="gap-2 ">
                  <FaUserLock className="icon" /> JLG
                </span>

                <span>
                  <MdArrowDropDown
                    className="icon"
                    style={{ display: "flex", alignSelf: "flex-end" }}
                  />
                </span>
              </div>
            </div>
          </div>
          {isJlgDropdownOpen && (
            <ul style={{ listStyle: "none" }} className="nested-list-master">
              {(isSuperAdmin || isDeveloper) && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Center Details" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Center Details");
                    navigate("/centerDetails", {
                      state: {
                        manager: "",
                        filterStatus: "",
                        isActive: "Center Details",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Center Details
                  </span>
                </li>
              )}
              <li
                className={`nested-list-master-item ${
                  isActive === "Approved" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Approved");
                  navigate("/centerDetails", {
                    state: {
                      filterStatus: "approved",
                      isActive: "Approved",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <BiSolidUserDetail className="icon" /> Approved
                </span>
              </li>
              {!(isSuperAdmin || isDeveloper) && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Retrigger" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Retrigger");
                    navigate("/centerDetails", {
                      state: {
                        filterStatus: "retrigger",
                        isActive: "Retrigger",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Retrigger
                  </span>
                </li>
              )}
              <li
                className={`nested-list-master-item ${
                  isActive === "Pending" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Pending");
                  navigate("/centerDetails", {
                    state: {
                      filterStatus: "pending",
                      isActive: "Pending",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <BiSolidUserDetail className="icon" /> Pending
                </span>
              </li>
              <li
                className={`nested-list-master-item ${
                  isActive === "Rejected" ? "active" : ""
                } p-2`}
                onClick={() => {
                  setIsActive("Rejected");
                  navigate("/centerDetails", {
                    state: {
                      filterStatus: "rejected",
                      isActive: "Rejected",
                    },
                  });
                }}
                style={{ borderRadius: 5 }}
              >
                <span className="gap-2">
                  <BiSolidUserDetail className="icon" /> Rejected
                </span>
              </li>
              {isBranchManager && (
                <li
                  className={`nested-list-master-item ${
                    isActive === "Disbursed" ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    setIsActive("Disbursed");
                    navigate("/centerDetails", {
                      state: {
                        filterStatus: "disbursed",
                        isActive: "Disbursed",
                      },
                    });
                  }}
                  style={{ borderRadius: 5 }}
                >
                  <span className="gap-2">
                    <BiSolidUserDetail className="icon" /> Disburse
                  </span>
                </li>
              )}
            </ul>
          )}

          <div className=" sidebar-list-item">
            <div
              className=" sidebarhover"
              style={{
                borderBottom: "0px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              <div
                onClick={toggleReportsDropdown}
                style={{
                  borderBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="gap-2">
                  <TbReportAnalytics className="icon" /> BL Reports
                </span>

                <span>
                  <MdArrowDropDown
                    className="icon"
                    style={{ display: "flex", alignSelf: "flex-end" }}
                  />
                </span>
              </div>
            </div>
          </div>
          {isReportsDropdownOpen && (
            <ul style={{ listStyle: "none" }} className="nested-list-master">
              {adminUser && adminUser.permissions && (
                <>
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "futureDemandReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Future Demand Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Future Demand Report");
                        navigate("/futureDemandReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Future Demand Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "loanDisbursedReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Loan Disbursed Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Loan Disbursed Report");
                        navigate("/loanDisbursedReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Loan Disbursed Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "masterDataReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Master Data" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Master Data");
                        navigate("/masterDataReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Master Data Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "clientProspectReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Client Prospect Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Client Prospect Report");
                        navigate("/clientProspectReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Client Prospect Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "outstandingReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Outstanding Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Outstanding Report");
                        navigate("/outstandingReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Outstanding Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "overDueReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "OverDue Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("OverDue Report");
                        navigate("/overDueReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> OverDue Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "parReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "PAR Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("PAR Report");
                        navigate("/parReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> PAR Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "securityDepositReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Security Deposit Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Security Deposit Report");
                        navigate("/securityDepositReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Security Deposit Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "processingChargeReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Processing Charge Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Processing Charge Report");
                        navigate("/processingChargeReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Processing Charge Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "collectionReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Collection Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Collection Report");
                        navigate("/collectionReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Collection Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "demandVsCollectionReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Demand Vs Collection Report"
                          ? "active"
                          : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Demand Vs Collection Report");
                        navigate("/demandVsCollectionReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Demand Vs Collection
                        Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "rejectReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Reject Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Reject Report");
                        navigate("/rejectReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Reject Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "accountStatementReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Account Statement Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Account Statement Report");
                        navigate("/accountStatementReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Account Statement Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "insuranceReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Insurance Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Insurance Report");
                        navigate("/insuranceReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Insurance Report
                      </span>
                    </li>
                  )}
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "interestReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "Interest Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("Interest Report");
                        navigate("/interestReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> Interest Report
                      </span>
                    </li>
                  )}
                </>
              )}
            </ul>
          )}
          <div className=" sidebar-list-item">
            <div
              className=" sidebarhover"
              style={{
                borderBottom: "0px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              <div
                onClick={toggleJlgReportsDropdown}
                style={{
                  borderBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="gap-2">
                  <TbReportAnalytics className="icon" /> JLG Reports
                </span>

                <span>
                  <MdArrowDropDown
                    className="icon"
                    style={{ display: "flex", alignSelf: "flex-end" }}
                  />
                </span>
              </div>
            </div>
          </div>
          {isJlgReportsDropdownOpen && (
            <ul style={{ listStyle: "none" }} className="nested-list-master">
              {adminUser && adminUser.permissions && (
                <>
                  {adminUser.permissions.some(
                    (permission) =>
                      permission.reportName === "jlgInsuranceReport" &&
                      permission.view === true
                  ) && (
                    <li
                      className={`nested-list-master-item ${
                        isActive === "JLG Insurance Report" ? "active" : ""
                      } p-2`}
                      onClick={() => {
                        setIsActive("JLG Insurance Report");
                        navigate("/jlgInsuranceReport");
                      }}
                      style={{ borderRadius: 5 }}
                    >
                      <span className="">
                        <TbReport className="icon" /> JLG Insurance Report
                      </span>
                    </li>
                  )}
                </>
              )}
            </ul>
          )}
        </div>

        <div className="logout" onClick={handleLogoutClick}>
          <div className="logout2">
            <span>
              <FiLogOut />
            </span>
            <span>Logout</span>
          </div>
        </div>
      </aside>
    </section>
  );
};

export default Sidebar;
