import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import Select from "react-select";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../env";

const TransferCro = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [fromCroOptions, setFromCroOptions] = useState([]);
  const [toCroOptions, setToCroOptions] = useState([]);
  const [selectedFromCro, setSelectedFromCro] = useState({
    value: "",
    label: "--- Select an Option ---",
    isDisabled: true, // Disable this option
  });
  const [selectedToCro, setSelectedToCro] = useState({
    value: "",
    label: "--- Select an Option ---",
    isDisabled: true, // Disable this option
  });

  const transformCroData = (data) => [
    {
      value: "",
      label: "--- Select an Option ---",
      isDisabled: true, // Disable this option
    },
    ...data.map((item) => ({
      value: item.id, // Use the unique identifier for the value
      label: item.username, // Display meaningful label
      branchId: item.branchId, // Include branchId if needed for further use
    })),
  ];

  const getToCroData = async (branchId, croId) => {
    // console.log("branchId: " + branchId);
    try {
      const response = await axios.get(`${BASE_URL}/api/getCroData`, {
        params: { branchId, croId },
      });
      if (response.data.message) {
        const transformedData = transformCroData(response.data.message);
        setToCroOptions(transformedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getFromCroData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/getCroData`);
        if (response.data.message) {
          const transformedData = transformCroData(response.data.message);
          setFromCroOptions(transformedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFromCroData();
  }, []);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const handleFromCroChange = (selectedOption) => {
    setSelectedFromCro(selectedOption);
    // console.log("selectedOption: " + JSON.stringify(selectedOption));
    getToCroData(selectedOption.branchId, selectedOption.value);
  };

  const handleToCroChange = (selectedOption) => {
    setSelectedToCro(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/transferCro`, {
        fromCroId: selectedFromCro.value,
        toCroId: selectedToCro.value,
      });
      if (response.data.message) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="d-flex ">
        <div style={{ width: "14%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          style={{
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
            }}
            className="p-3"
          >
            <h1>Transfer CRO</h1>
            <Row>
              <Col xs={12} md={6}>
                <label>From</label>
                <Select
                  value={selectedFromCro}
                  onChange={handleFromCroChange}
                  options={fromCroOptions}
                  placeholder="--- Select From ---"
                />
              </Col>
              <Col xs={12} md={6}>
                <label>To</label>
                <Select
                  value={selectedToCro}
                  onChange={handleToCroChange}
                  options={toCroOptions}
                  placeholder="--- Select To ---"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mt-3">
                <Button onClick={handleSubmit} className="btn btn-dark">
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransferCro;
