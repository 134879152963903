import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./MemberPrint.css";

import {
  DetailTable,
  PhotoTable,
  NomineePhotoTable,
  memberFields,
  businessFields,
  residenceFields,
  permanentResidenceFields,
  CoApplicantPersonalFields,
  coApplicantBusinessFields,
  coApplicantResidenceFields,
  coApplicantPermanentResidenceFields,
  loanDetailsFields,
  proposedLoanDetailsFields,
  bankDetailsFields,
} from "../components/TablePrintFormat";

import MemberSanctionLetter from "../components/MemberSanctionLetter";
import DisbursementRequestForm from "../components/DisbursementRequestForm";
import GeneratedEmiSchedule from "../components/GeneratedEmiSchedule";

const MemberDetailPrint = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const location = useLocation();
  const { memberDetails } = location.state;
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    const input = ref.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const originalPdfWidth = pdf.internal.pageSize.getWidth();
    const originalPdfHeight = pdf.internal.pageSize.getHeight();
    const pdfWidth = originalPdfWidth * 0.9;
    const pdfHeight = originalPdfHeight * 0.9;
    const xOffset = (originalPdfWidth - pdfWidth) / 2;
    const yOffset = (originalPdfHeight - pdfHeight) / 2;
    const sections = input.querySelectorAll(".section");

    let heightLeft = pdfHeight;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { useCORS: true, scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (
        heightLeft < imgHeight ||
        section.classList.contains("sanction-letter-section")
      ) {
        pdf.addPage();
        heightLeft = pdfHeight;
      }

      pdf.addImage(
        imgData,
        "JPEG",
        xOffset,
        yOffset + (pdfHeight - heightLeft),
        pdfWidth,
        imgHeight
      );
      heightLeft -= imgHeight;

      // Add border to the current page after content is added
      pdf.rect(5, 5, originalPdfWidth - 10, originalPdfHeight - 10); // Rect (x, y, width, height)
    }

    pdf.setProperties({
      title: "Document",
      subject: "Generated Document",
      author: "Your Name",
      keywords: "generated, javascript, web 2.0, ajax",
      creator: "Your App",
    });

    pdf.save(`${memberDetails.memberDetails.memberName}-Loan Application`);
    setLoading(false);
  };

  return (
    <div>
      <div className="button-container">
        <button className="btn btn-danger" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="btn btn-success"
          onClick={handleDownload}
          disabled={loading}
        >
          {loading ? "Downloading..." : "Download PDF"}
        </button>
      </div>

      <div ref={ref} className="print-container">
        <div>
          <div className="header section">
            <p>Application form - Micro Enterprise Loan</p>
          </div>
          <div className="section">
            <div className="sub-header">
              <p>Applicant's Personal Details</p>
            </div>

            <div className="d-flex">
              <DetailTable
                details={memberDetails.memberDetails}
                fields={memberFields}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <PhotoTable
                  details={memberDetails.memberDocuments}
                  fields={[
                    {
                      key: "memberPhoto",
                      label: "Applicant ",
                    },
                  ]}
                />
                <NomineePhotoTable
                  details={memberDetails.nomineeDocuments}
                  fields={[
                    {
                      key: "nomineePhoto",
                      label: "Co-Applicant",
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Employment Details</p>
            </div>
            <DetailTable
              details={memberDetails.businessDetails}
              fields={businessFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Applicant Residence Address</p>
            </div>
            <DetailTable
              details={memberDetails.memberDetails}
              fields={residenceFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Applicant Permanent Address</p>
            </div>
            <DetailTable
              details={memberDetails.memberDetails}
              fields={permanentResidenceFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Co-Applicant Personal Details</p>
            </div>
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={CoApplicantPersonalFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Co-Applicant Employment Details</p>
            </div>
            <DetailTable
              details={memberDetails.familyBusinessDetails}
              fields={coApplicantBusinessFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Co-Applicant Residence Address</p>
            </div>
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={coApplicantResidenceFields}
            />
          </div>
          <div className="section">
            <div className="sub-header">
              <p>Co-Applicant Permanent Address</p>
            </div>
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={coApplicantPermanentResidenceFields}
            />
          </div>
          <div className="section">
            <div className="sub-header">
              <p>Existing Loan Details</p>
            </div>
            <DetailTable
              details={memberDetails.loanDetails}
              fields={loanDetailsFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Proposed Loan Details</p>
            </div>
            <DetailTable
              details={{
                ...memberDetails.proposedLoanDetails,
                // Override emiDate with the appropriate value
                emiDate:
                  memberDetails?.memberDetails?.emiDateByBranchManager ??
                  memberDetails.proposedLoanDetails.emiDate,
              }}
              fields={proposedLoanDetailsFields}
            />
          </div>

          <div className="section">
            <div className="sub-header">
              <p>Bank Details</p>
            </div>
            <DetailTable
              details={memberDetails.bankDetails}
              fields={bankDetailsFields}
            />
          </div>
          <div className="section">
            <div className="sub-header">
              <p>Signature of Applicant and Co-Applicant</p>
            </div>
            <table className="member-details-table">
              <tbody>
                <tr>
                  <td>
                    <div style={{ display: "flex" }}>
                      <div className="label-container">
                        <p>Applicant's Signature</p>
                      </div>
                      <div className="label-container">
                        <p></p>
                      </div>
                      <div className="label-container">
                        <p>Co-Applicant's Signature</p>
                      </div>
                      <div className="value-container">
                        <p></p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="section sanction-letter-section d-flex justify-content-end ">
              <img
                src={require("../assets/vetrivikaslogo.jpeg")}
                alt="logo"
                className="printlogo"
              />
            </div>
            <MemberSanctionLetter memberDetails={memberDetails} />
          </div>
          <div>
            <DisbursementRequestForm memberDetails={memberDetails} />
          </div>
          <div>
            <div className="section sanction-letter-section d-flex justify-content-end ">
              <img
                src={require("../assets/vetrivikaslogo.jpeg")}
                alt="logo"
                className="printlogo"
              />
            </div>
            <GeneratedEmiSchedule memberDetails={memberDetails} />
            <div
              className="section d-flex justify-content-between"
              style={{ fontSize: "20px" }}
            >
              <div className="mt-5">
                <p> ________________</p>
                <p>Signature Of Applicant</p>
                <p>விண்ணப்பதாரரின் கையொப்பம்</p>
              </div>
              <div className="mt-5">
                <p> ________________</p>
                <p>Signature Of Branch Manager</p>
                <p> கிளை மேலாளர் கையொப்பம் </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetailPrint;
