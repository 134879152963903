import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { InputGroup, FormControl, Button, Row, Col } from "react-bootstrap";
import MemberDetailsModal from "../components/MemberDetailsModal";
import { useLocation } from "react-router-dom";
import ActionButtons from "../components/ActionButtons";
import Zoom from "react-medium-image-zoom";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardContent,
  Alert,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupDetailsModal from "../components/GroupDetailsModal";
import ActionButtonsJlg from "../components/ActionButtonsJlg";
import GenerateDocumentsByBranchManagerModal from "../components/GenerateDocumentsByBranchManagerModal";
import UpdateMeetingDetailsByBmModal from "../components/UpdateMeetingDetailsByBmModal";
import { TiTick } from "react-icons/ti";
import { IoSearch, IoDocumentTextOutline } from "react-icons/io5";

const CenterDetails = () => {
  const location = useLocation();
  const { filterStatus } = location.state || {};
  const { adminUser } = useAdminAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [centerData, setCenterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showViewMemberModal, setShowViewMemberModal] = useState(false);
  const [showViewGroupModal, setShowViewGroupModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showGenerateDocumentsModal, setShowGenerateDocumentsModal] =
    useState(false);
  const [
    showUpdateMeetingDetailsByBmModal,
    setShowUpdateMeetingDetailsByBmModal,
  ] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("error"); // "success" | "error" | "warning" | "info"
  const [alertMessage, setAlertMessage] = useState("");

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleConfirmUpdateMeetingDetails = async (
    bmMeetingDayOrder,
    bmMeetingTime
  ) => {
    try {
      const payload = {};
      payload.id = selectedCenterId;
      payload.bmMeetingDayOrder = bmMeetingDayOrder;
      payload.bmMeetingTime = bmMeetingTime;
      await axios.post(`${BASE_URL}/api/updateMeetingDetailsByBm`, {
        ...payload,
      });
      fetchCenterDetails();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowUpdateMeetingDetailsByBmModal(false);
    }
  };

  const handleCloseUpdateMeetingDetails = async () => {
    setShowUpdateMeetingDetailsByBmModal(false);
  };

  const fetchCenterDetails = useCallback(async () => {
    try {
      const statusMapping = {
        "Branch Manager": {
          approved: { branchManagerStatus: ["submitted", "disbursed"] },
          retrigger: { branchManagerStatus: "retrigger" },
          pending: {
            fieldManagerStatus: "submitted",
            branchManagerStatus: "pending",
            misStatus: "pending",
          },
          rejected: { branchManagerStatus: "rejected" },
          disbursed: {
            branchManagerStatus: ["submitted", "disbursed"],
            misStatus: "submitted",
            creditManagerStatus: "submitted",
            accountManagerStatus: ["pending", "submitted"],
          },
        },
        MIS: {
          approved: { misStatus: "submitted" },
          retrigger: { misStatus: "retrigger" },
          pending: { branchManagerStatus: "submitted", misStatus: "pending" },
          rejected: { misStatus: "rejected" },
        },
        "Credit Manager": {
          approved: { creditManagerStatus: "submitted" },
          retrigger: { creditManagerStatus: "retrigger" },
          pending: {
            fieldManagerStatus: "submitted",
            branchManagerStatus: "submitted",
            misStatus: "submitted",
            creditManagerStatus: "pending",
          },
          rejected: { creditManagerStatus: "rejected" },
        },
        "Sanction Committee": {
          approved: { sanctionCommitteeStatus: "approved" },
          retrigger: { sanctionCommitteeStatus: "retrigger" },
          pending: {
            fieldManagerStatus: "submitted",
            branchManagerStatus: "submitted",
            misStatus: "submitted",
            creditManagerStatus: "submitted",
            sanctionCommitteeStatus: "pending",
          },
          rejected: { sanctionCommitteeStatus: "rejected" },
        },
        "Accounts Manager": {
          approved: { accountManagerStatus: "submitted" },
          retrigger: { accountManagerStatus: "retrigger" },
          pending: {
            fieldManagerStatus: "submitted",
            branchManagerStatus: "disbursed",
            misStatus: "submitted",
            creditManagerStatus: "submitted",
            accountManagerStatus: "pending",
          },
          rejected: { accountManagerStatus: "rejected" },
        },
        superadmin: {
          approved: { accountManagerStatus: "submitted" },
          pending: {
            accountManagerStatus: "pending",
          },
          rejected: {
            orConditions: [
              { branchManagerStatus: "rejected" },
              { misStatus: "rejected" },
              { creditManagerStatus: "rejected" },
              { accountManagerStatus: "rejected" },
            ],
          },
        },
        developer: {
          approved: { accountManagerStatus: "submitted" },
          pending: {
            accountManagerStatus: "pending",
          },
          rejected: {
            orConditions: [
              { branchManagerStatus: "rejected" },
              { misStatus: "rejected" },
              { creditManagerStatus: "rejected" },
              { accountManagerStatus: "rejected" },
            ],
          },
        },
      };

      const baseStatus =
        statusMapping[adminUser.userType]?.[filterStatus] || {};

      const queryParams = new URLSearchParams({
        managerId: adminUser.user.id,
      });

      Object.entries(baseStatus).forEach(([key, value]) => {
        if (key === "orConditions") {
          // Convert orConditions to JSON string for inclusion
          queryParams.append(key, JSON.stringify(value));
        } else {
          // Add other conditions directly
          queryParams.append(key, value);
        }
      });

      const response = await axios.get(
        `${BASE_URL}/api/getCentersByManager?${queryParams.toString()}`
      );
      setCenterData(response.data.data);
      // console.log("centerData: " + JSON.stringify(response.data.data));
    } catch (error) {
      console.log("Error fetching center details:", error);
    }
  }, [adminUser, filterStatus]);

  useEffect(() => {
    fetchCenterDetails();
  }, [fetchCenterDetails]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCenters.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredCenters.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleClickViewMember = (memberId) => {
    setSelectedMemberId(memberId);
    setShowViewMemberModal(true);
  };

  const handleClickViewGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setShowViewGroupModal(true);
  };

  const handleClickGenerateDocument = (groupId, centerId) => {
    setSelectedCenterId(centerId);
    setSelectedGroupId(groupId);
    setShowGenerateDocumentsModal(true);
  };

  const handleClickUpdateMeetingDetails = async (center) => {
    setSelectedCenterId(center.id);
    setSelectedCenter(center);

    try {
      const response = await axios.get(
        `${BASE_URL}/api/getModelData/center?includeDepth=3&filter={"id":${center.id}}`
      );

      if (response.data.data[0]) {
        const unfilteredCenter = response.data.data[0];

        const isEligibleForUpdate =
          unfilteredCenter.fk_center_hasMany_group_centerId.every((group) => {
            const validMembers =
              group.fk_group_hasMany_member_details_groupId.filter((member) => {
                return member.branchManagerStatus === "disbursed";
              });
            return validMembers.length === 0;
          });

        if (isEligibleForUpdate) {
          setShowUpdateMeetingDetailsByBmModal(true);
        } else {
          // Show alert when update is not allowed
          setAlertSeverity("error");
          setAlertMessage(
            "Cannot update as some members disbursed in this center."
          );
          setAlertOpen(true);
        }
      }
    } catch (error) {
      console.log("Error fetching center details:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch center details. Please try again.");
      setAlertOpen(true);
    }
  };

  const renderAccordion = (data) => {
    return data.map((center) => (
      <Accordion
        key={center.id}
        sx={{
          backgroundColor: "#f0f4ff", // Light blue for centers
          marginBottom: "10px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#0047ab" }}
          >
            {center.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Center Name: {center.name}
          <br />
          Meeting Day(Week No,Day): {center.bmMeetingDayOrder}
          <br />
          Meeting Time:{center.bmMeetingTime}
          <br />
          {adminUser.userType === "Branch Manager" &&
            center.fk_center_hasMany_group_centerId.every((group) => {
              const validMembers =
                group.fk_group_hasMany_member_details_groupId.filter(
                  (member) => {
                    return member.branchManagerStatus === "disbursed";
                  }
                );
              return validMembers.length === 0;
            }) && (
              <div className="mb-1">
                <Button
                  variant="success"
                  onClick={() => handleClickUpdateMeetingDetails(center)}
                >
                  <TiTick size={20} /> Update Meeting Details
                </Button>
              </div>
            )}
          {/* Groups Accordion */}
          <Accordion
            sx={{
              backgroundColor: "#e8f5e9", // Light green for groups
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              marginBottom: "10px",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", color: "#388e3c" }}
              >
                Groups
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {center.fk_center_hasMany_group_centerId.map((group) => (
                <Accordion
                  key={group.id}
                  sx={{
                    backgroundColor: "#fff9c4", // Light yellow for individual groups
                    borderRadius: "8px",
                    marginBottom: "10px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "bold", color: "#f57c00" }}
                    >
                      {group.leaderName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Button
                      onClick={() => handleClickViewGroup(group.id)}
                      className="m-1"
                    >
                      View Group
                    </Button>
                    {adminUser.userType === "Branch Manager" &&
                      group.fk_group_hasMany_member_details_groupId?.length >=
                        4 &&
                      group.fk_group_hasMany_member_details_groupId?.length <=
                        6 && (
                        <>
                          <Button
                            onClick={() =>
                              handleClickGenerateDocument(group.id, center.id)
                            }
                            className="m-1"
                          >
                            GenerateDocument
                          </Button>
                        </>
                      )}

                    {/* Members Accordion */}
                    <Accordion
                      sx={{
                        backgroundColor: "#ffe0b2", // Light orange for members section
                        borderRadius: "8px",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold", color: "#d84315" }}
                        >
                          Members
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {group.fk_group_hasMany_member_details_groupId
                          .filter((member) => {
                            if (
                              adminUser.userType === "Branch Manager" &&
                              filterStatus === "disbursed"
                            ) {
                              return member.branchManagerStatus === "submitted";
                            }
                            return true; // Display all members if the condition is not met
                          })
                          .map((member) => (
                            <Card
                              variant="outlined"
                              key={member.id}
                              sx={{
                                backgroundColor: "#fbe9e7", // Light coral for individual members
                                marginBottom: "8px",
                                borderRadius: "6px",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Member Name: {member.memberName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#6d4c41" }}
                                >
                                  Application ID: {member.ApplicationId}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    handleClickViewMember(member.id)
                                  }
                                  className="my-3 mx-1"
                                >
                                  View Member
                                </Button>
                                {member.cbReport && (
                                  <Button
                                    variant="primary"
                                    className="mx-1"
                                    onClick={() =>
                                      window.open(
                                        `${BASE_URL}/images/${member.cbReport}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <IoDocumentTextOutline />
                                  </Button>
                                )}
                                {member.coApplicantCbReport && (
                                  <Button
                                    variant="primary"
                                    className="mx-1"
                                    onClick={() =>
                                      window.open(
                                        `${BASE_URL}/images/${member.coApplicantCbReport}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <IoDocumentTextOutline />
                                  </Button>
                                )}
                                <ActionButtonsJlg
                                  roleId={adminUser.userType}
                                  member={member}
                                  center={center}
                                  group={group}
                                  updateList={fetchCenterDetails}
                                  managerId={adminUser.user.id}
                                  proposedLoanAmount={
                                    member.proposedLoanDetails?.loanAmount ?? 0
                                  }
                                />
                              </CardContent>
                            </Card>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <div>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>
        <div
          className=""
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            className="member-details "
            style={{
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Typography variant="h4" style={{ marginBottom: "20px" }}>
              Center Details
            </Typography>
            <div>{renderAccordion(centerData)}</div>
          </div>
          {/* Custom Pagination */}
          <nav
            className="custom-pagination"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "8%",
              width: "100%",
              backgroundColor: "#202020",
              fontSize: "14px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px -2px 15px -2px",
            }}
          >
            <button
              style={{
                marginLeft: "15px",
                backgroundColor: "#323232",
                padding: "6px 15px",
                borderRadius: "6px",
                border: "transparent",
                color: "#ECEFF1",
              }}
            >
              Total Data: {filteredCenters.length}
            </button>

            <ul
              className="pagination justify-content-end"
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="prev-btn "
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
              <li key={currentPage} className="page-item active">
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage)}
                >
                  Page {currentPage} / {totalPages}
                </button>
              </li>
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="prev-btn "
                  style={{ width: "80px", borderRadius: "0 5px 5px 0" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
        {showViewMemberModal && (
          <MemberDetailsModal
            show={showViewMemberModal}
            handleClose={() => setShowViewMemberModal(false)}
            memberId={selectedMemberId}
          />
        )}
        {showViewGroupModal && (
          <GroupDetailsModal
            show={showViewGroupModal}
            handleClose={() => setShowViewGroupModal(false)}
            groupId={selectedGroupId}
            roleId={adminUser.userType}
          />
        )}
        {showGenerateDocumentsModal && (
          <GenerateDocumentsByBranchManagerModal
            show={showGenerateDocumentsModal}
            handleClose={async () => {
              await fetchCenterDetails();
              setShowGenerateDocumentsModal(false);
            }}
            handleConfirm={async () => {
              await fetchCenterDetails();
              setShowGenerateDocumentsModal(false);
            }}
            groupId={selectedGroupId}
            centerId={selectedCenterId}
            managerId={adminUser.user.id}
          />
        )}

        {showUpdateMeetingDetailsByBmModal && (
          <UpdateMeetingDetailsByBmModal
            show={showUpdateMeetingDetailsByBmModal}
            handleClose={handleCloseUpdateMeetingDetails}
            handleConfirm={handleConfirmUpdateMeetingDetails}
            message={"Are you sure you want to Update?"}
            item={selectedCenter}
          />
        )}
        <Snackbar
          open={alertOpen}
          autoHideDuration={3000}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={alertSeverity} onClose={() => setAlertOpen(false)}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default CenterDetails;
