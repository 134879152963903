import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../env";
import { FaDownload, FaUpload } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import UploadBranchManagerDocument from "./UploadBranchManagerDocument";

const GenerateDocumentsByBranchManagerModal = ({
  show,
  handleClose,
  handleConfirm,
  groupId,
  centerId,
  managerId,
}) => {
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState(null);
  const [dbMemberDetails, setDbMemberDetails] = useState(null);
  const [
    showUploadBranchManagerDocumentModal,
    setShowUploadBranchManagerDocumentModal,
  ] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const dbMemberLookup = dbMemberDetails?.reduce((acc, dbmember) => {
    acc[dbmember.id] = dbmember;
    return acc;
  }, {});

  const currentISTDateTime = new Date().toISOString();

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (groupId) {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/getModelData/member_details?filter={"groupId":${groupId}}&includeDepth=1`
          );
          setMemberDetails(response.data.data);
          setDbMemberDetails(response.data.data);
          // console.log(
          //   "members under group: " + JSON.stringify(response.data.data)
          // );
        } catch (error) {
          console.error("Error fetching members under group details:", error);
        }
      }
    };

    fetchMemberDetails();
  }, [groupId]);

  const handleClick = async () => {
    const updatedMembers = memberDetails.map((member) => {
      const loanAmount = member.proposedLoanDetails?.loanAmount || 0;
      const rateOfInterest = member.proposedLoanDetails?.rateOfInterest || 0;
      const tenureInMonths = member.proposedLoanDetails?.tenureInMonths || 0;

      const processingCharge = Math.round((loanAmount * 2) / 100);
      const gst = Math.round((processingCharge * 18) / 100);
      const monthlyRate = rateOfInterest / 12 / 100;
      const securityDeposit = Math.round(
        (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, tenureInMonths)) /
          (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
      );
      const insurancePercentage =
        tenureInMonths === 24 ? 2.284 : tenureInMonths === 12 ? 1.142 : 0;
      const insuranceAmount = Math.round(
        (loanAmount * insurancePercentage) / 100
      );
      const isInsuranceAmountPaid = member.isInsuranceAmountPaid;

      const amountToRelease =
        loanAmount -
        processingCharge -
        gst -
        securityDeposit -
        (isInsuranceAmountPaid ? 0 : insuranceAmount);

      return {
        ...member,
        processingCharge,
        isProcessingChargePaid: false,
        gst,
        isGstPaid: false,
        securityDeposit,
        isSecurityDepositPaid: false,
        isLoanInsured: true,
        isInsuranceAmountPaid,
        insuranceAmount,
        amountToRelease,
      };
    });

    setMemberDetails(updatedMembers);
    try {
      await axios.post(`${BASE_URL}/api/updateGenerateDocumentDetails`, {
        members: updatedMembers,
        centerId,
        bmStatusUpdatedAt: currentISTDateTime,
      });
      const response = await axios.get(
        `${BASE_URL}/api/getModelData/member_details?filter={"groupId":${groupId}}&includeDepth=1`
      );
      setMemberDetails(response.data.data);
      setDbMemberDetails(response.data.data);
    } catch (error) {
      console.log("Error updating list:", error);
    }
  };

  const handleDownload = async (memberId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/loan/formdetails/${memberId}`
      );
      navigate("/memberdetail/jlg/print", {
        state: {
          memberDetails: response.data,
        },
      });
    } catch (error) {
      console.log("Error fetching list:", error);
    }
  };
  const handleUpload = async (memberId) => {
    setSelectedMemberId(memberId);
    setShowUploadBranchManagerDocumentModal(true);
  };

  const handleConfirmUploadBranchManagerDocuments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/getModelData/member_details?filter={"groupId":${groupId}}&includeDepth=1`
      );
      setMemberDetails(response.data.data);
      setDbMemberDetails(response.data.data);
      setShowUploadBranchManagerDocumentModal(false);
    } catch (error) {
      console.log("Error updating list:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="full-width-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3">
          <table className="table table-bordered table-striped">
            <thead className="bg-secondary">
              <tr>
                <th>Member Name</th>
                <th>Mark</th>
                <th>Proposed Loan Amount</th>
                <th>Processing Charge (2.5 % loan amount)</th>
                <th>GST (18 % processing charge)</th>
                <th>Security Deposit (1 month emi amount)</th>
                <th>Is Insurance Collected</th>
                <th>Insurance Amount</th>
                <th>Loan Amount To Release</th>
                <th>Download/Upload</th>
                <th>Uploaded file names</th>
              </tr>
            </thead>
            <tbody>
              {memberDetails?.map((member) => {
                const loanAmount = member.proposedLoanDetails?.loanAmount || 0;
                const rateOfInterest =
                  member.proposedLoanDetails?.rateOfInterest || 0;
                const tenureInMonths =
                  member.proposedLoanDetails?.tenureInMonths || 0;
                const processingCharge = Math.round((loanAmount * 2) / 100);
                const gst = Math.round((processingCharge * 18) / 100);
                const monthlyRate = rateOfInterest / 12 / 100;
                const securityDeposit = Math.round(
                  (loanAmount *
                    monthlyRate *
                    Math.pow(1 + monthlyRate, tenureInMonths)) /
                    (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
                );
                const insurancePercentage =
                  tenureInMonths === 24
                    ? 2.284
                    : tenureInMonths === 12
                    ? 1.142
                    : 0;
                const insuranceAmount = Math.round(
                  (loanAmount * insurancePercentage) / 100
                );
                const amountToRelease =
                  loanAmount -
                  processingCharge -
                  gst -
                  securityDeposit -
                  (member.isInsuranceAmountPaid ? 0 : insuranceAmount);
                const validDbMembersCount = dbMemberDetails?.filter(
                  (dbmember) =>
                    dbmember.isAttendedBank && dbmember.processingCharge
                ).length;
                const validMembersCount = memberDetails?.filter(
                  (dbmember) =>
                    dbmember.isAttendedBank && dbmember.processingCharge
                ).length;

                return (
                  <tr key={member.id}>
                    <td>{member.memberName}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={!!member.isAttendedBank}
                        onChange={(e) => {
                          const updatedMembers = memberDetails.map((m) =>
                            m.id === member.id
                              ? { ...m, isAttendedBank: e.target.checked }
                              : m
                          );
                          setMemberDetails(updatedMembers);
                        }}
                        disabled={member.branchManagerStatus === "disbursed"}
                      />
                    </td>
                    <td>{loanAmount}</td>
                    <td>{member.processingCharge || processingCharge}</td>
                    <td>{member.gst || gst}</td>
                    <td>{member.securityDeposit || securityDeposit}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={!!member.isInsuranceAmountPaid}
                        onChange={(e) => {
                          const updatedMembers = memberDetails.map((m) =>
                            m.id === member.id
                              ? {
                                  ...m,
                                  isInsuranceAmountPaid: e.target.checked,
                                }
                              : m
                          );
                          setMemberDetails(updatedMembers);
                        }}
                        disabled={member.branchManagerStatus === "disbursed"}
                      />
                    </td>
                    <td>{member.insuranceAmount || insuranceAmount}</td>
                    <td>{member.amountToRelease || amountToRelease}</td>
                    <td>
                      {member.branchManagerStatus !== "disbursed" &&
                        dbMemberLookup[member.id]?.isAttendedBank &&
                        dbMemberLookup[member.id]?.processingCharge &&
                        member.isAttendedBank &&
                        member.processingCharge &&
                        validMembersCount >= 4 &&
                        validMembersCount <= 6 &&
                        validDbMembersCount >= 4 &&
                        validDbMembersCount <= 6 && (
                          <>
                            <Button
                              variant="primary"
                              onClick={() => handleDownload(member.id)}
                              className="mx-3"
                            >
                              <FaDownload />
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => handleUpload(member.id)}
                              className="mx-3"
                            >
                              <FaUpload />
                            </Button>
                          </>
                        )}
                    </td>
                    <td>
                      {member.branchManagerVerificationPhotosDetails?.map(
                        (photo, index) => (
                          <React.Fragment key={index}>
                            <a
                              href={`${BASE_URL}/images/${photo.fileName}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {photo.photoName}
                            </a>
                            {index !==
                              member.branchManagerVerificationPhotosDetails
                                .length -
                                1 && ", "}
                          </React.Fragment>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button variant="primary" onClick={handleClick}>
            Generate Documents to Download
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            await handleClick();
            await handleConfirm();
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
      {showUploadBranchManagerDocumentModal && (
        <UploadBranchManagerDocument
          show={showUploadBranchManagerDocumentModal}
          handleClose={() => setShowUploadBranchManagerDocumentModal(false)}
          handleConfirm={handleConfirmUploadBranchManagerDocuments}
          memberId={selectedMemberId}
          branchManagerId={managerId}
        />
      )}
    </Modal>
  );
};

export default GenerateDocumentsByBranchManagerModal;
