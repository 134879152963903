import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col, Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import * as utilFields from "../components/utils";
import FormInput from "../components/FormInput";
import * as schemas from "../components/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";

const AddEditEntityDetails = () => {
  const location = useLocation();
  const { type, id, entity } = location.state || {};

  const [entityData, setEntityData] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modelName, setModelName] = useState("");
  const [validationSchema, setValidationSchema] = useState();
  const [viewNavPath, setViewNavPath] = useState("");
  const [editNavPath, setEditNavPath] = useState("");
  const [title, setTitle] = useState("");
  const [filePreviews, setFilePreviews] = useState({});
  const [existingFiles, setExistingFiles] = useState({});
  const [isImageExist, setIsImageExist] = useState(false);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  // Update the data, fields, and schema based on the entity
  useEffect(() => {
    const entityConfig = {
      fundingAgencies: {
        initialData: utilFields.initialFundingAgencyDetails,
        fields: utilFields.fundingAgencyDetailsFields,
        validationSchema: schemas.fundingAgencyDetailsValidationSchema,
        model: "funding_agencies",
        viewNavigationPath: "/fundingAgencies",
        editNavigationPath: "/addEditFundingAgency",
        title: "Funding Agency Details",
        isImageExist: false,
      },
    };
    if (entityConfig[entity]) {
      const {
        initialData,
        fields,
        validationSchema,
        model,
        viewNavigationPath,
        editNavigationPath,
        title,
        isImageExist,
      } = entityConfig[entity];
      if (type === "Add") {
        setEntityData(initialData);
      }
      console.log("fields: " + JSON.stringify(fields));
      setFieldsData(fields);
      setValidationSchema(validationSchema);
      setModelName(model);
      setViewNavPath(viewNavigationPath);
      setEditNavPath(editNavigationPath);
      setTitle(title);
      setIsImageExist(isImageExist);
    } else {
      setEntityData({});
      setFieldsData([]);
      setValidationSchema(null);
      setModelName("");
      setViewNavPath("");
      setEditNavPath("");
      setTitle("");
      setIsImageExist(false);
    }
    setLoading(false);
  }, [entity, type]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema), // Fallback to default schema
    defaultValues: entityData,
  });

  useEffect(() => {
    const fetchEntityData = async (id, modelName) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getModelData/${modelName}?filter={"id":${id}}`
        );
        const fetchedData = response.data.data[0];
        setEntityData(fetchedData);
        setFilePreviews({});
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false);
      }
    };

    if ((type === "View" || type === "Edit") && modelName) {
      setLoading(true);
      fetchEntityData(id, modelName);
    } else {
      setLoading(false);
    }
  }, [type, id, modelName, entity]);

  useEffect(() => {
    if (entityData) {
      Object.keys(entityData).forEach((key) => {
        setValue(key, entityData[key]);
        if (isImageExist) {
          if (key.endsWith("Image") && entityData[key]) {
            setExistingFiles((prev) => ({
              ...prev,
              [key]: `${BASE_URL}/images/${entityData[key]}`, // Construct the image URL
            }));
          }
        }
      });
    }
  }, [entityData, setValue, isImageExist]);

  const onSubmit = async (data) => {
    try {
      let response;
      if (!isImageExist) {
        const payload = {
          ...data,
          model: modelName,
          type,
        };

        response = await axios.post(`${BASE_URL}/api/addDetails`, payload);
      }
      if (isImageExist) {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
          if (filePreviews[key]?.file) {
            formData.append(key, filePreviews[key].file);
          } else {
            // Append other non-file fields
            if (value !== null && value !== "") {
              formData.append(key, value);
            }
          }
        });

        formData.append("model", modelName);
        formData.append("type", type);

        response = await axios.post(
          `${BASE_URL}/api/addDetailsWithImages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true, // If cookies are needed
          }
        );
      }

      if (response.status === 201) {
        setEntityData(response.data.savedData);
        console.log("Details added successfully: ", response.data);
        if (type === "Add") {
          navigate(viewNavPath, { state: { entity: entity } });
        }
        if (type === "Edit") {
          navigate(editNavPath, {
            state: { type: "View", id, entity },
          });
        }
      } else {
        console.log("Error Updating Details: ", response.data.error);
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response:", error.response.data);

        if (
          error.response.status === 400 &&
          error.response.data.error === "Unique constraint violation."
        ) {
          alert(
            `Error: ${
              error.response.data.error
            }\nDetails: ${error.response.data.details.join(", ")}`
          );
        } else {
          alert(`An error occurred: ${error.response.data.error}`);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
        alert("An error occurred. Please try again.");
      }
    }
  };
  if (loading) {
    return (
      <div className="overlay">
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }

  return (
    <section>
      <div className="d-flex">
        <div style={{ width: "14%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          className=""
          style={{
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <h2 className="text-center mb-4">
              {title}
              {type === "View" && (
                <Button
                  className="btn btn-primary"
                  onClick={() =>
                    navigate(editNavPath, {
                      state: { type: "Edit", id, entity },
                    })
                  }
                >
                  Edit
                </Button>
              )}
            </h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {fieldsData.map((field, index) => (
                  <Col
                    xs={12}
                    md={field.type === "file" ? 12 : 6}
                    key={index}
                    className="mb-3"
                  >
                    <FormInput
                      label={field.label}
                      type={field.type}
                      name={field.name}
                      register={register}
                      errors={errors}
                      options={field.options || []}
                      readOnly={type === "View" || field.readOnly}
                      handleFileChange={(e) =>
                        utilFields.handleFileChange(
                          e,
                          field.name,
                          setFilePreviews
                        )
                      }
                      existingFileUrl={existingFiles[field.name] || ""}
                      filePreview={filePreviews[field.name]?.previewUrl || ""}
                      required={field.required}
                    />
                  </Col>
                ))}
              </Row>
              <div className="text-center">
                {!(type === "View") && (
                  <Button variant="success" type="submit" className="mt-3">
                    {type === "Edit" ? "Update" : "Submit"}
                  </Button>
                )}{" "}
                {type === "Edit" && (
                  <Button
                    variant="warning"
                    className="mt-3"
                    onClick={() =>
                      navigate(editNavPath, {
                        state: { type: "View", id, entity },
                      })
                    }
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditEntityDetails;
