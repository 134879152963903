import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import {
  generateRowsWithEmiAndCollectedAmounts,
  getFirstAndLastEmiDates,
} from "../components/utils";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";
import { useAdminAuth } from "../components/AdminAuthProvider";

const DemandVsCollectionReport = () => {
  const { adminUser } = useAdminAuth();
  const [reportData, setReportData] = useState([]);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBranchFilter, setSelectedBranchFilter] = useState(null);
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(null);

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async (id) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getDemandVsCollectionReportData`,
          { params: { id } }
        );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData(adminUser.user.id);
  }, [adminUser.user.id]);

  const flattenedData = reportData.flatMap((data) =>
    generateRowsWithEmiAndCollectedAmounts(data, fromDate, toDate)
  );

  const totalPages = Math.ceil(flattenedData.length / itemsPerPage);

  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Region", render: (data) => data.regionName },
    { label: "Division", render: (data) => data.divisionName },
    { label: "Branch Name", render: (data) => data.branchName },
    { label: "Branch Code", render: (data) => data.branchCode },
    { label: "Customer Name", render: (data) => data.memberName },
    { label: "Customer ID", render: (data) => data.customerId },
    {
      label: "Address",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Contact No", render: (data) => data.phoneNumber },
    { label: "Loan Type", render: (data) => "Business Loan" },
    {
      label: "Sub Category",
      render: (data) => data.businessDetails.natureOfBusiness,
    },
    { label: "Loan Cycle", render: (data) => data.loanCycle },
    { label: "Loan Account No", render: (data) => data.loanId },
    {
      label: "Rate of Interest",
      render: (data) => data.proposedLoanDetails.rateOfInterest,
    },
    {
      label: "Disbursement Date",
      render: (data) =>
        data.branchManagerStatus === "disbursed" &&
        data.branchManagerStatusUpdatedAt
          ? new Date(data.branchManagerStatusUpdatedAt).toLocaleDateString(
              "en-GB",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
    },
    {
      label: "Tenure In Months",
      render: (data) => data.proposedLoanDetails.tenureInMonths,
    },
    {
      label: "EMI months Paid",
      render: (data) => data.emiMonthsPaid,
    },
    {
      label: "EMI Date",
      render: (data) =>
        data.formattedEmiDateByBranchManager.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Loan Amount",
      render: (data) =>
        data.branchManagerStatus === "disbursed"
          ? data.sanctionedLoanAmountBySanctionCommittee
          : "",
    },
    { label: "Employee Name", render: (data) => data.employeeName },
    {
      label: "EMI Amount",
      render: (data) => Math.round(data.emiAmount),
    },
    {
      label: "Collected Amount",
      render: (data) => Math.round(data.collectedEmiAmount),
    },
    {
      label: "Payment Details",
      render: (data) => data.collectedDates,
    },
  ];

  const branchOptions = [
    ...new Set(flattenedData.map((data) => data?.branchName)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  const employeeOptions = [
    ...new Set(flattenedData.map((data) => data?.username)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  const filterData = (data) => {
    let filteredData = data;

    if (selectedBranchFilter) {
      filteredData = filteredData.filter(
        (item) => item?.branchName === selectedBranchFilter.value
      );
    }

    if (selectedEmployeeFilter) {
      filteredData = filteredData.filter(
        (item) => item?.username === selectedEmployeeFilter.value
      );
    }

    return filteredData;
  };

  const filteredData = filterData(flattenedData).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div className="dashboard-over">
      <div className="d-flex" style={{ width: "100vw" }}>
        <div style={{ width: "13%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          className="container-fluid"
          style={{
            // overflowY: "scroll",
            width: "87%",
            height: "100vh",
            // scrollbarWidth: "none",
          }}
        >
          <div style={{ height: "23%", zIndex: "5", position: "relative" }}>
            <h1>Demand Vs Collection Report</h1>
            <div style={{ position: "absolute", width: "100%" }}>
              <Row style={{ fontWeight: "500" }}>
                <Col lg={2}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      value={fromDate}
                      onChange={(event) => setFromDate(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      value={toDate}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(event) => setToDate(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={3}>
                  <Form.Label>Branch</Form.Label>
                  <Select
                    value={selectedBranchFilter}
                    onChange={setSelectedBranchFilter}
                    options={branchOptions}
                    isClearable
                    placeholder="Filter by Branch Name"
                    className="filter-dropdown"
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <Form.Label>Employee</Form.Label>
                  <Select
                    value={selectedEmployeeFilter}
                    onChange={setSelectedEmployeeFilter}
                    options={employeeOptions}
                    isClearable
                    placeholder="Filter by Employee Name"
                    className="filter-dropdown"
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ height: "69%" }}>
            <DataTable
              columns={columns}
              data={filteredData}
              reportName="DemandVsCollectionReport"
              excelData={flattenedData}
            />
          </div>

          <div
            style={{
              height: "8%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandVsCollectionReport;
