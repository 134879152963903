import React, { useState, useEffect } from "react";
import { FaPen, FaTrash, FaEye } from "react-icons/fa";
import styles from "./TablePagination.module.css";

const TablePagination = ({
  tabledata,
  initialRowsPerPage,
  imagecolumns,
  showViewOption,
  showEditOption,
  showDeleteOption,
  onClickView,
  onClickEdit,
  onClickDel,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(tabledata.length / rowsPerPage)
  );
  const columns = tabledata.length > 0 ? Object.keys(tabledata[0]) : [];
  const imageColumnsExist =
    Array.isArray(imagecolumns) && imagecolumns.length > 0;

  useEffect(() => {
    setIsLastPage(totalPages <= 1);
    setRowsPerPage(
      initialRowsPerPage > tabledata.length
        ? tabledata.length
        : initialRowsPerPage
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRowsPerPageChange(event) {
    const value = event.target.value;
    setRowsPerPage(parseInt(value, 10));
    const newTotalPages = Math.ceil(tabledata.length / parseInt(value, 10));
    setTotalPages(newTotalPages);
    setCurrentPage(1);
    setIsFirstPage(true);
    setIsLastPage(newTotalPages <= 1);
  }

  function handleFirstClick() {
    setCurrentPage(1);
    setIsFirstPage(true);
    setIsLastPage(false);
  }

  function handlePreviousClick() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    setIsFirstPage(newCurrentPage === 1);
    setIsLastPage(newCurrentPage === totalPages);
  }

  function handleNextClick() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    setIsFirstPage(newCurrentPage === 1);
    setIsLastPage(newCurrentPage === totalPages);
  }

  function handleLastClick() {
    setCurrentPage(totalPages);
    setIsFirstPage(false);
    setIsLastPage(true);
  }

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Number(startIndex) + Number(rowsPerPage);
  const currentPageData = tabledata.slice(startIndex, endIndex);

  const rowsPerPageOptions = Array.from(
    { length: tabledata.length },
    (_, i) => i + 1
  );

  return (
    <div className="table-responsive m-3">
      <table className="table table-striped  text-center ">
        <thead>
          <tr>
            <th>No</th>
            {columns.map((column, index) => {
              if (column !== "id") {
                return <th key={index}>{column.toUpperCase()}</th>;
              }
              return null;
            })}
            {(showViewOption || showEditOption || showDeleteOption) && (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((rows, index) => (
            <tr key={index}>
              <td style={{ fontSize: 18, fontWeight: 500 }}>{index + 1}</td>
              {columns.map((column, index1) => {
                if (column !== "id") {
                  return (
                    <td key={index1} style={{ fontSize: 18, fontWeight: 500 }}>
                      {imageColumnsExist &&
                      imagecolumns.includes(column) &&
                      rows[column] !== "" ? (
                        <img
                          src={"/images/" + rows[column]}
                          alt="sampleimg"
                          width="50"
                          height="50"
                        />
                      ) : (
                        rows[column]
                      )}
                    </td>
                  );
                }
                return null;
              })}
              {(showViewOption || showEditOption || showDeleteOption) && (
                <td>
                  {showViewOption && (
                    <FaEye
                      style={{ color: "green", fontSize: 20 }}
                      className={`${styles.clickable}  m-1`}
                      onClick={() => onClickView(rows.id)}
                    />
                  )}
                  {showEditOption && (
                    <FaPen
                      style={{ color: "blue", fontSize: 20 }}
                      className={`${styles.clickable}  m-1`}
                      onClick={() => onClickEdit(rows.id)}
                    />
                  )}
                  {showDeleteOption && rows.is_default !== 1 && (
                    <FaTrash
                      style={{ color: "red", fontSize: 20 }}
                      className={`${styles.clickable}  m-1`}
                      onClick={() => onClickDel(rows.id)}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`${styles.tablepagebtn}`}>
        <div className="form-group">
          Rows per page:
          <select
            className="form-control ml-2"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            {rowsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <button
            className="btn btn-outline-secondary mr-2"
            onClick={handleFirstClick}
            disabled={isFirstPage}
          >
            First
          </button>
          <button
            className="btn btn-outline-secondary mr-2"
            onClick={handlePreviousClick}
            disabled={isFirstPage}
          >
            Previous
          </button>
          <button
            className="btn btn-outline-secondary mr-2"
            onClick={handleNextClick}
            disabled={isLastPage}
          >
            Next
          </button>
          <button
            className="btn btn-outline-secondary"
            onClick={handleLastClick}
            disabled={isLastPage}
          >
            Last
          </button>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
